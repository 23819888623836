<template>
  <v-form v-model="valid" ref="deliveryForm">
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
        <v-select v-model="coffee" :items="['Kiboko (Dry Cherry Coffee)','Fair Average Qualit','Red Cherries']" label="Bean" />
        </v-col>
        <v-row class="d-flex justify-center mb-3">  
          <v-btn-toggle
            v-model="toggle_exclusive"
            mandatory
          >
            <v-btn :class="{ success: !toggle_exclusive }" class="mr-3">Hulled</v-btn>
            <v-btn :class="{ success: toggle_exclusive }">Not Hulled</v-btn>
          </v-btn-toggle>
        </v-row>
        <v-col cols="12" md="4">
          <v-select v-model="coffee.grade" :items="robustaCoffeeOptions" label="Grade" />
        </v-col>
        <v-col cols="12" md="4">
          <TextInput v-model="coffee.moistureContent" required label="Moisture Content" type="number" suffix="%"/>
        </v-col>
        <v-col cols="12" md="4">
          <TextInput v-model="coffee.sample" required label="Sample out turn" type="number" suffix="%"/>
        </v-col>
        <v-col cols="12" md="4">
          <TextInput v-model="coffee.quantity" required label="Weight" type="number" suffix="KG"/>
        </v-col>
        <v-col cols="12" md="4">
          <TextInput v-model="coffee.processingCost" required label="Processing costs" type="number" suffix="UGX"/>
        </v-col>
        <v-col cols="12" md="4">
          <TextInput v-model="coffee.price" label="Price/Kg" type="number" suffix="UGX"/>
        </v-col>
        <v-col cols="12" md="4">
          <TextInput v-model="coffeeValue" readonly="true" label="Value" type="number" suffix="UGX" />
        </v-col>
        <v-col cols="12" md="4">
          <TextInput v-model="coffeeNetValue" readonly="true" label="Net Value" type="number" suffix="UGX"/>
        </v-col>
      </v-row>
      <v-row class="mx-3 my-4 d-flex flex-row-reverse">
        <v-btn color="primary" class="mb-2" @click="validateAndContinue" :disabled="!valid">Next</v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import TextInput from "@/components/TextInput";
export default {
  components: { TextInput },
  data: () => ({
    coffee: {},
    toggle_exclusive: undefined,
    robustaCoffeeOptions: [18,17,15,12,'Undergrade/triage'],
    arabicaCoffeeOptions: ['AA','A','A+','AB','CPB','Undergade'],
    valid: false,
  }),
  mounted() {
    this.coffee = {
      price: Math.floor(Math.random() * 1000) + 2000,
      quantity: 0,
      processingCost: 0
    }
  },
  computed: {
    coffeeValue() { return this.coffee.quantity*this.coffee.price},
    coffeeNetValue() { return this.coffeeValue-this.coffee.processingCost}
  },
  methods: {
    validate() {
      this.$refs.deliveryForm.validate();
    },
    toCustomers() {
      this.$router.push({ name: 'Home' });
    },
    validateAndContinue(){
      this.validate();
      // Post coffee data here
      this.toCustomers()
    }
  }
}
</script>
