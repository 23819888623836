<template>
  <v-card>

    <v-card-title>
      {{$t('demographics')}}
      <v-spacer />
      <v-btn v-if="editDemographics" color="primary" small dense @click="edit">{{$t('edit')}}</v-btn>
<!--      <v-btn v-if="partnerCode !== partnerCodes.hr_co" color="primary" small dense @click="edit">{{$t('edit')}}</v-btn>-->
    </v-card-title>

    <v-card-text>
      <v-simple-table v-if="anyFields" >
        <template v-slot:default>
          <tbody>
            <Row :title="$t('Age')" :number="age" unit="years" v-if="age" />
            <Row :title="$t('Birthdate')" :date="birthdate" v-if="birthdate" />
            <Row :title="$t('Gender')" :gender="gender" v-if="gender" />
            <Row :title="$t('Dependents')" :number="dependents" v-if="!(isNaN(dependents))" />
            <Row :title="$t('Schooling')" :number="schooling" unit="years" v-if="schooling" />
            <Row :title="$t('Preferred Language')" :text="language" v-if="showLanguage && language" />
          </tbody>
        </template>
      </v-simple-table>
      <div v-else>
        {{$t('noData')}}
      </div>
    </v-card-text>

  </v-card>
</template>


<script>

  import Row from '@/components/Row';
  import partnerCodeEnums from "@/mixins/partnerCodeEnums";

  export default {

    components: {
      Row,
    },

    props: ['customer'],

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      demographic() { return this.customer.demographic || {} },

      age() { return this.demographic.age; },
      birthdate() { return this.demographic.birthdate; },
      dependents() { return this.demographic.dependents; },
      schooling() { return this.demographic.schooling; },
      language() { return this.demographic.language; },
      gender() { return this.demographic.gender; },

      anyFields() { return (this.age || this.birthdate || this.dependents || this.schooling || this.language || this.gender); },

      partnerCode() { return this.$store.getters.settings.partnerCode; },
      showLanguage() { return !['apex_tz', 'hr_co', 'winwin_zw'].includes(this.partnerCode); },
      editDemographics() { return this.partnerCode !==this.partnerCodes.hr_co; },
    },

    methods: {
      edit() { this.$router.push({name: 'EditDemographics', query: { uuid: this.customerUuid }}); },
    },
    mixins: [partnerCodeEnums]

  }
</script>


<i18n>
{
  "en": {
    "demographics": "Demographics",
    "noData": "No demographic data defined.",
    "Age": "Age",
    "Birthdate": "Birthdate",
    "Gender": "Gender",
    "Dependents": "Dependents",
    "Schooling": "Schooling",
    "Preferred Language" : "Preferred Language"
  },
  "es": {
    "demographics": "Agricultor",
    "noData": "No se han definido datos demográficos.",
    "Age": "Años",
    "Birthdate": "Fecha de Nacimiento",
    "Gender": "Género",
    "Dependents": "Personas a cargo (económicamente)",
    "Schooling": "Escolaridad",
    "Preferred Language" : ""
  },
  "sw": {
    "demographics": "Taarifa",
    "noData": "Hakuna data ya idadi ya watu iliyofafanuliwa.",
    "Age": "Umri",
    "Birthdate": "Siku ya kuzaliwa",
    "Gender": "Jinsia",
    "Dependents": "Dependents",
    "Schooling": "Kusoma",
    "Preferred Language" : "Preferred Language"
  }
}
</i18n>
