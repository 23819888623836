<template>

  <v-container fluid>
    <v-row class="text-center">
      <v-col>
        <InstrumentUltimateTzCheckMeBack :customer="customer" :instrumentId="instrumentId" />
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col class="text-h4 red--text">
        {{customer.name}} has an open loan and is ineligible for another Check Me Back loan.
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import InstrumentUltimateTzCheckMeBack from "@/views/instruments/InstrumentUltimateTzCheckMeBack"

export default {

  components: { InstrumentUltimateTzCheckMeBack },

  props: ['customer', 'instrumentId'],

}
</script>
