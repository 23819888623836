<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <Instrument :customer="customer" :instrumentId="instrumentId" :instrumentType="instrumentType" highlight="Balance" @instrument="setInstrument" />
      </v-col>
    </v-row>

    <v-row v-if="instrument">

      <v-col cols="6">
        <Widget title="Transaction History">
          <CustomerFacingTransactions :instrument="instrument" />
        </Widget>
      </v-col>

      <v-col cols="12" md="2" sm="3" v-if="instrumentType=='InstrumentUltimateTzCheckMeBack'">
        <Value>
          {{instrument.installment_period}}
          /
          {{instrument.installments}}
        </Value>
      </v-col>
      <v-col cols="12" md="2" sm="3" v-if="instrument.due_at">
        <Date label="Next Due" :value="instrument.due_at" />
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import Widget from "@/components/Widget";
import CustomerFacingTransactions from "@/views/instruments/CustomerFacingTransactions"
import Instrument from "@/views/instruments/Instrument"
import Value from "@/views/instruments/components/Value"
import Date from "@/views/instruments/components/Date"

export default {

  components: { Widget, CustomerFacingTransactions, Instrument, Value, Date },

  props: {
    value: {
      type: Object,
      required: false,
    },
    customer: {
      type: Object,
      required: true,
    },
    balances: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      instrument: undefined,
    }
  },

  computed: {
    instrumentId() { return this.balances.id; },
    instrumentType() { return this.balances.type; },
    // installments() { return (this.balances ? this.balances.duration : undefined); },

  },

  watch: {

  },

  methods: {
    setInstrument(to) {
      this.instrument = to;
      this.$emit('input', this.instrument);
    },
  },

}
</script>
