<template>
  <v-form ref="testForm" v-model="validForm">
    <v-container fluid class="text-left">

      <v-row :class="{ 'red': !form.typeId, 'lighten-5': !form.typeId }">
        <v-col cols="4">
          <v-text-field label="Customer UUID" hint="The UUID or identifier that this image must be attached to." v-model="form.typeId" />
        </v-col>
        <v-col class="body-1" cols="8">
          PhotoInput and DocumentInput need to know which customer to attach this image or document to
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>

      <v-row class="text-left">
        <v-col cols="4">
          Required
        </v-col>
        <v-col cols="4">
          Not Required
        </v-col>
        <v-col cols="4">
          Slot (not required)
        </v-col>
      </v-row>

      <v-row class="text-left">
        <v-col cols="4">
          <PhotoInput v-model="form.selfie_one" type="id_scan" :typeId="form.typeId" required />
        </v-col>
        <v-col cols="4">
          <PhotoInput v-model="form.selfie_two" type="selfie" :typeId="form.typeId" />
        </v-col>
        <v-col cols="4">
          <PhotoInput v-model="form.selfie_three" type="selfie" :typeId="form.typeId">
            [Click here to take a picture]
          </PhotoInput>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="3">
          Default
        </v-col>
        <v-col cols="3">
          Labelled, Required
        </v-col>
        <v-col cols="3">
          Pimped
        </v-col>
        <v-col cols="3">
          Slot (Required)
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <DocumentInput v-model="form.doc_one" type="selfie" :typeId="form.typeId" />
        </v-col>
        <v-col cols="3">
          <DocumentInput v-model="form.doc_two" type="selfie" :typeId="form.typeId" required label="Bank Statement" />
        </v-col>
        <v-col cols="3">
          <DocumentInput v-model="form.doc_three" type="selfie" :typeId="form.typeId" caption="Caption, my caption" label="Pimped" icon="mdi-file-plus" />
        </v-col>
        <v-col cols="3">
          <DocumentInput v-model="form.doc_four" type="selfie" :typeId="form.typeId" required accept=".pdf">
            [PDF Only]
          </DocumentInput>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <PhotoInput v-model="showImageOne" type="id_scan" :typeId="'zsdfsdfsdgsfdd'" required />
          Show Images
          <DisplayImage :image-uuid="showImageOne" />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right">
          <v-btn color="warning" small dense class="mr-1" @click="this.validate">
            {{$t('validate')}}
          </v-btn>
          <v-btn color="warning" small dense @click="this.saveForm" :disabled="!validForm">
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>

    </v-container>

  </v-form>
</template>

<script>
import PhotoInput from "@/components/PhotoInput";
import DocumentInput from "@/components/DocumentInput";
import GenderInput from "@/components/GenderInput";
import SingleImage from "@/components/SingleImage";
import DisplayImage from "@/components/DisplayImage";

export default {

  components: {
    PhotoInput,
    GenderInput,
    DocumentInput,
    SingleImage,
    DisplayImage,
  },

  data() {
    return {
      validForm: false,
      form: {},
      showImageOne: null
    }
  },

  methods: {
    validate(event) {
      this.validating = true;
      this.$refs.testForm.validate();
    },
    saveForm(event) {
      console.log('saveForm', event)
    }
  }

}
</script>
