export default {

  data() {
    return {
      collectorSettings: undefined,
      characteristic: null,
      device:       null,
    }
  },

  methods: {
    async printReceipt(farmer={}, receiptDetails) {
      console.log("farmer_details2222: ", farmer)
      console.log("details1234: ", receiptDetails)
      const encoder = new TextEncoder('utf-8');
      let text_element = '\n'
      // const optionalDevice = BluetoothUUID.getService('0000ffe0-0000-1000-8000-00805f9b34fb')
      const optionalDevice = BluetoothUUID.getService('000018f0-0000-1000-8000-00805f9b34fb')
      try {
          if (!this.characteristic) {
            this.device = await navigator.bluetooth.requestDevice({
              // filters: [{
              //   services: ['000018f0-0000-1000-8000-00805f9b34fb'] // Replace with your printer's service UUID
              // }],
              optionalServices: [optionalDevice],
              acceptAllDevices: true
            });
            console.log("device: ", this.device)
            const server = await this.device.gatt.connect();
            const service = await server.getPrimaryService('000018f0-0000-1000-8000-00805f9b34fb'); // Replace with your printer's service UUID
            this.characteristic = await service.getCharacteristic('00002af1-0000-1000-8000-00805f9b34fb'); // Replace with your printer's characteristic UUID

            let first_title = `${farmer.receiptType}\nProcessor: ${farmer.processor}\nFarmer Name: ${farmer.farmer}\nFarmerNo: ${farmer.farmerNo}\n`
            text_element = text_element.concat(first_title)
            
            let billText = ''
            // let billBytes = encoder.encode(billText + '\u000A\u000D');
            // await this.characteristic.writeValue(billBytes);


            // let second_title = `Farmer Name: ${farmer.farmer}\nFarmerNo: ${farmer.farmerNo}\n`
            // billText = second_title
            // billBytes = encoder.encode(billText + '\u000A\u000D'); 
            // await this.characteristic.writeValue(billBytes);
            console.log("is array: ", Array.isArray(receiptDetails))
            console.log("receiptDetails: ",receiptDetails)
            if(Array.isArray(receiptDetails)) {
              console.log("reciptDetails: ", receiptDetails)
              for(let element of receiptDetails) {
                let list_sentence = element.join("   ")
                text_element = text_element.concat('\n', list_sentence)
                console.log('Bill sent to printer.');
                console.log('Connected to printer.');
              }

            } else {
              text_element = text_element.concat('\n')
              console.log("single: ", receiptDetails)
              for (const [key, value] of Object.entries(receiptDetails)) {
                billText =`${key}: ${value} \n\n`;
                text_element = text_element.concat(" ", billText)
              }
              console.log('Bill sent to printer.');
              console.log('Connected to printer.');
              
            }
          } else {
            console.log('Already connected to printer.');
            const server = await this.device.gatt.connect();
            const service = await server.getPrimaryService('000018f0-0000-1000-8000-00805f9b34fb'); // Replace with your printer's service UUID
            this.characteristic = await service.getCharacteristic('00002af1-0000-1000-8000-00805f9b34fb');
            console.log("device: ", this.characteristic.service)
            console.log("characteristic: ", this.characteristic)

            let first_title = `${farmer.receiptType}\nProcessor: ${farmer.processor}\nFarmer Name: ${farmer.farmer}\nFarmerNo: ${farmer.farmerNo}\n`
            text_element = text_element.concat(first_title)


            if(Array.isArray(receiptDetails)) {
              console.log("reciptDetails: ", receiptDetails)
              for(let element of receiptDetails) {
                let list_sentence = element.join("   ")
                text_element = text_element.concat('\n', list_sentence)
                // console.log('Bill sent to printer.');
                // console.log('Connected to printer.');
              }

            } else {
              for (const [key, value] of Object.entries(receiptDetails)) {
                let billText =`${key}: ${value} \n\n`;
                text_element = text_element.concat(" ", billText)
              }
              console.log('Bill sent to printer.');
              console.log('Connected to printer.');
              
            }          
          }

          let billBytes = encoder.encode(text_element + '\u000A\u000D'); // Adding line feed + carriage return
          let chunkSize = 20; // Assume MTU size is 20 bytes
          for (let i = 0; i < billBytes.length; i += chunkSize) {
            let chunk = billBytes.slice(i, i + chunkSize);
            await this.characteristic.writeValue(chunk).then(() => {
                console.log("Chunk written successfully");
            }).catch(error => {
                console.error("Failed to write chunk", error);
                this.device.addEventListener('gattserverdisconnected', this.onDisconnected);
            });
          }

          text_element = ''
          let date = new Date()
          let printed_time = `${date.toLocaleDateString("de-DE")} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
          let footer = `\nTotal kgs: ${farmer.cumulative}\nPrinted by: ${farmer.agent}\nPrinted At: ${printed_time}\nGenerated by: OneFarm\n \n`
          text_element = text_element.concat('', footer)
          text_element = text_element.concat('', '\n')

          billBytes = encoder.encode(text_element + '\u000A\u000D'); // Adding line feed + carriage return
          chunkSize = 20; // Assume MTU size is 20 bytes
          for (let i = 0; i < billBytes.length; i += chunkSize) {
            let chunk = billBytes.slice(i, i + chunkSize);
            await this.characteristic.writeValue(chunk).then(() => {
                console.log("Chunk written successfully");
            }).catch(error => {
                console.error("Failed to write chunk", error);
                this.device.addEventListener('gattserverdisconnected', this.onDisconnected);
            });
          }
          console.log("service",this.characteristic.service)      
        } catch (error) {
          console.log("this is the error: ", error)
          console.error('Error:', error.message);
        }
    },
    onDisconnected(event) {
      console.log('Device disconnected', event);
      // Attempt to reconnect after a delay
      setTimeout(() => {
          connectToDevice();
      }, 5000);
    }
  }
}
