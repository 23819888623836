<template>
  <v-form ref="employmentForm" v-model="valid" class="text-left" >


    <v-row>
      <v-col cols="12" md="6">
        <BusinessInput v-model="customer.employer" required label="Employer" style="min-width: 100px; max-width: 100%;" />
      </v-col>
      <v-col cols="12" md="6" v-if="customer.employer">
        <v-chip  color="success" label outlined dense v-if="customer.employer.government">
          <v-icon left>mdi-gavel</v-icon>
          Government
        </v-chip>
        <v-chip  color="primary" label outlined dense v-else-if="customer.employer.category=='bank'">
          <v-icon left>mdi-bank</v-icon>
          Bank
        </v-chip>
        <v-chip  color="warning" label outlined dense v-else>
          <v-icon left>mdi-domain</v-icon>
          Private Business
        </v-chip>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" md="3">
        <v-text-field label="Department number" v-model="customer.department_number" />
      </v-col>
      <v-col cols="12" md="3">
        <NameInput v-model="customer.department" label="Department" required />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field label="Work Address" v-model="customer.work_address" :rules="[v => !!v || 'Required']" />
      </v-col>
      <v-col cols="12" md="3">
        <MsisdnInput v-model="customer.work_phone_number" label="Work Phone Number" />
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" md="3">
        <v-text-field :label="employeeNumberCaption" v-model="customer.employee_number" :rules="[v => (v || '').length>(government ? 4 : 0) || 'Required']" :disabled="!customer.employer" />
      </v-col>
      <v-col cols="12" md="3">
        <DateInput v-model="customer.employed_at" label="Start Date" required dropLists past />
      </v-col>
      <v-col cols="12" md="3">
        <DateInput v-model="customer.employment_contract_ends_at" label="Contract End Date" dropLists future />
      </v-col>
      <v-col cols="12" md="3">
        <DateInput v-model="customer.retires_at" label="Retirement Date" required dropLists future :min="customer.employment_contract_ends_at" max="2060-01-01T12:00" />
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" md="3">
        <CurrencyInput v-model="customer.economic.income" label="Gross Salary" required />
      </v-col>
      <v-col cols="12" md="3">
        <CurrencyInput v-model="customer.economic.nettPay" label="Nett Pay" required />
      </v-col>
      <v-col cols="12" md="3">
        <CurrencyInput v-model="customer.economic.adjustedNettPay" label="Adjusted Nett Pay" required />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
            label="Work HR Description / Supervisor"
            v-model="customer.work_hr_description"
        />
      </v-col>
    </v-row>


  </v-form>

</template>

<script>

import BusinessInput from "@/components/BusinessInput";
import DateInput from "@/components/DateInput";
import NameInput from "@/components/NameInput";
import MsisdnInput from "@/components/MsisdnInput";
import CurrencyInput from "@/components/CurrencyInput";

export default {

  components: { BusinessInput, DateInput, NameInput, MsisdnInput, CurrencyInput },

  props: ['value'],

  data() {
    return {
      valid: false,
      customer: undefined,
    }
  },

  computed: {
    government() { return (this.customer.employer && this.customer.employer.government); },
    employeeNumberCaption() {
      let r = 'Emplyee Number';
      if (this.government) {
        r = 'Check Number';
      }
      return r;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.customer = to; }
    },
    customer(to) { this.$emit('input', to); },
    valid(to) { this.$emit('valid', to); },
  },

  methods: {
    validate() {
      this.$refs.employmentForm.validate();
    },
  },

}
</script>
