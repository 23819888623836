<template>
  <v-text-field
    v-model="text"
    :rules="allRules"
    :label="label"
    :type="type"
    :suffix="suffix"
    :number="number"
    :disabled="disabled"
    :min="min"
    :readonly="readonly"
  ></v-text-field>
</template>

<script>
  export default {
    props: {
      value: String,
      readonly: Boolean,
      required: Boolean,
      disabled: Boolean,
      number: Boolean,
      rules: Array,
      label: String,
      min: Number,
      max: Number,
      type: {
        type: String,
        default: 'text',
      },
      suffix: String,
    },
    data () {
      return {
        text: null
      }
    },
    computed: {
      allRules() { return [this.defaultRules, ...(this.rules || [])]; },
    },
    watch: {
      value: {
        immediate: true,
        handler(to) { this.text = to; }
      },
      text(to) { this.$emit('input', to); },
    },
    methods: {
      defaultRules(value) {
        let r = [
          v => !this.required || !!((v || '').trim()) || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
    },
  };
</script>