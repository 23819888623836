<template>

  <v-row justify="center" v-if="authenticated && requireTermsAndConditions">
    <v-dialog
        v-model="acceptedTermsAndConditionsAt"
        persistent
    >
      <HRTermsAndConditions
          v-if="partnerCode === 'dairy_ke'"
          @updateTermsAndConditions="updateTermsAndConditions"
      />
    </v-dialog>
  </v-row>

</template>

<script>
import HRTermsAndConditions from "@/components/TermsAndConditions/partners/hr_co/HRTermsAndConditions.vue";

export default {
  components: {
    HRTermsAndConditions
  },
  data() {
    return {
      dialog: true
    }
  },
  computed: {
    partnerCode() { return this.$store.getters.partnerCode; },
    authenticated() { return this.$store.getters.authenticated; },
    requireTermsAndConditions() { return this.$store.getters.partnerCustomerSettings.require_terms_and_conditions || false; },
    agentTermsAndConditionsAt() {
      return this.$store.getters.agentTermsAndConditionsAt;
    },
    acceptedTermsAndConditionsAt() {
      return !this.agentTermsAndConditionsAt;
    },
  },
  methods: {
    updateTermsAndConditions() {
      const payload = {
        termsAndConditionTime: new Date()
      }
      this.$store.dispatch('acceptTermsAndConditions', payload);
    }
  }

}
</script>
