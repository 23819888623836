var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.theActions,"item-key":"action"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('Time',{attrs:{"time":item.created_at}})]}},{key:"item.activity",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.activity))])]}},{key:"item.customer_name",fn:function(ref){
var item = ref.item;
return [_c('ToCustomer',{attrs:{"uuid":item.customer_uuid,"name":item.customer_name}})]}},{key:"item.follow_up_at",fn:function(ref){
var item = ref.item;
return [_c('Time',{attrs:{"time":item.follow_up_at}})]}},{key:"item.closed_at",fn:function(ref){
var item = ref.item;
return [(item.closed_at)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-bold")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }