<template>

  <v-container fluid class="pa-0">
    <v-snackbar v-model="onlineWarning" timeout="5000" >
      {{$t('searchOnline')}}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="onlineWarning = false" >
          {{$t('close')}}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="networkError" timeout="5000" >
      {{$t('networkError')}}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="networkError = false" >
          {{$t('close')}}
        </v-btn>
      </template>
    </v-snackbar>


    <v-row no-gutters class="my-2">
      <v-col cols="12">
        <v-text-field
            :value="searchTextQuery"
            @input="searchTextHandler"
            hide-details
            :label="$t('typeToSearch')"
            :placeholder="$t('search')"
            full-width filled
            rounded
            light
            dense
            single-line
            append-icon="mdi-magnify" />
      </v-col>

    </v-row>


    <v-row no-gutters v-if="searchText.length<3">
      <v-col cols="12">
        {{$t('instructions')}}
      </v-col>
    </v-row>

    <template v-if="searchText.length>=3">
      <v-row no-gutters v-if="searchedCustomers.length===0">
        <v-col cols="12">
          {{$t('noResults')}}
        </v-col>
      </v-row>

      <v-row no-gutters v-else >
        <v-col cols="12">
          <Customers :customers="searchedCustomers" @show="show" />
        </v-col>
      </v-row>
    </template>

    

  </v-container>
  
</template>


<script>

  import Customers from '@/views/search/components/Index';
  import LoadAndShow from '@/views/customers/components/LoadAndShow';
  import lookups from "@/mixins/lookups";
  import {customDebounce} from "@/views/partners/hr_co/utils";

  export default {

    components: {
      Customers,
      LoadAndShow,
    },

    data: () => ({
      searchText: '',
      onlineWarning: true,
      networkError: false,
      favouritingInProgress: false,
      showCustomerForPartnerIdentifier: null,
      searchedCustomers: []
    }),

    computed: {
      customers() {
        return this.$store.getters.searchResults;
      },
      searchTextQuery() {
        return this.$route.query.searchText;
      },
    },

    watch: {
      '$route.query.searchText': {
        handler: customDebounce(function(searchText) {
          this.searchText = searchText
          this.apiSearchCustomers(searchText);
        },300),
        immediate: true
      }
    },

    methods: {
      show(customer) {
        this.$store.dispatch('searchedCustomers', customer)
        this.$router.push({ name: 'ShowCustomer', query: { uuid: customer.uuid } });
      },
      searchTextHandler(searchText) {
        this.$router.push({ name: 'Search', query: { searchText: searchText } });
      },
      apiSearchCustomers(searchText) {
        if ((searchText || '').length>2) {
          this.remoteLookup('searchCustomer', {search: searchText})
              .then( customersResponse => {
                this.searchedCustomers = customersResponse
              })
              // .catch(e => {
              //   this.networkError = true
              //   this.searchedCustomers = []
              // })
        }
      }
    },
    mixins: [lookups],
  }
</script>


<i18n>
{
  "en": {
    "close": "Close",
    "instructions": "We will start searching once you have entered three characters. You can search for names, telephone numbers, identity numbers and areas.",
    "search": "Search",
    "searchOnline": "Search online for farmers and contacts.",
    "networkError": "Network error",
    "typeToSearch": "Type to search",
    "noResults": "No profiles match your search criteria."
  },
  "es": {
    "close": "Cerrar",
    "instructions": "La búsqueda iniciará una vez ingrese los primeros tres caracteres. Puede buscar nombres, números de teléfono, números de identidad y áreas.",
    "search": "Búsqueda",
    "searchOnline": "Busque agricultores y contactos en línea.",
    "typeToSearch": "Escribe para buscar",
    "networkError": "Error de red",
    "noResults": "Ningún perfil coincide con sus criterios de búsqueda."
  },
  "sw": {
    "close": "Funga",
    "instructions": "Tutaanza kutafuta ukishaingiza herufi tatu. Unaweza kutafuta majina, nambari za simu, nambari za kitambulisho na maeneo.",
    "search": "Tafuta",
    "searchOnline": "Tatuta wakulima na mawasiliano.",
    "networkError": "Kosa la mtandao",
    "typeToSearch": "Andika ili utafute",
    "noResults": "Mafuta ya Ningún sanjari na con criterios de búsqueda."
  }
}
</i18n>
