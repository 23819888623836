<template>
  <v-select v-model="item" v-bind="$attrs" v-on="$listeners" :rules="[requiredRule]" />
</template>

<script>

export default {

  props: {
    value: undefined,
    required: Boolean,
  },

  data: () => ({
    item: undefined,
  }),

  watch: {
    value: {
      immediate: true,
      handler(to) { this.item = to; }
    },
    item(to) { this.$emit('input', this.item); },
  },

  methods: {
    requiredRule(value) {
      let r = [
        v => !!v || !this.required || v==0 || this.$t('required'),
      ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
      return ( typeof(r)==='string' ? r : true );
    },
  }
};

</script>