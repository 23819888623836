<template>
  <v-container fluid class="pa-0" v-if="formFields">
    <v-row>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-row>
    <v-row>

      <v-col cols="12" class="pa-0">
        <v-data-table
          dense
          :search="search"
          :headers="headers"
          :items="formFields.tableResults"
          item-key="item.uuid"
          class="w-100"
        >
          <template v-slot:header.description>{{ $t("Description") }}</template>
          <template v-slot:header.price_per_unit>{{ $t("Price") }} <small>/ {{ $t("unit") }}</small></template>
          <template v-slot:header.quantity>{{ $t("Quantity") }}</template>
          <template v-slot:header.cost>{{ $t("Cost") }}</template>

          <template v-slot:item.price_per_unit="{ item }">
            <Currency :value="item.price_per_unit" />
            <span class="hidden-xs-only pl-0"><small>/ {{ item.unit }}</small></span>
          </template>

          <template v-slot:item.quantity="{ item }">
            <v-text-field
              min="0"
              type="number"
              :rules="(item.unit === 'l' ? numberWithDecimalRules : numberRules)"
              style="text-align: right"
              v-model="item.quantity"
              @input="updateCost(item.uuid)"
            />
          </template>

          <template v-slot:item.cost="{ item }" class="hidden-xs-only">
            <Currency :value="Math.round(parseFloat(item.quantity) * item.price_per_unit)" />
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12 d-flex justify-space-between" class="font-weight-bold">
        <div></div>
        <div>
          Total
          <Currency :value="cost" />
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>


<script>
import { v4 as uuidv4 } from "uuid";

import validationRules from "@/mixins/validationRules";

import Currency from "@/components/Currency";

export default {

  name: "NucafeFarmerNeeds",

  components: {  Currency },

  props: ['value'],

  data: () => ({
    search: '',
    formFields: undefined,
  }),

  computed: {
    currency() { return this.$store.getters.currency; },
    cost() { return this.formFields.tableResults.map( e => (parseFloat(e.quantity) || 0) * parseFloat(e.price_per_unit) ).reduce((a, b) => a + b, 0); },
    headers() {
      return [
        {
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          align: "center",
          sortable: true,
          value: "price_per_unit",
        },
        {
          align: "start",
          sortable: false,
          value: "quantity",
        },
        {
          align: "end",
          sortable: false,
          value: "cost",
        },
      ];
    },
    productCategories() {
      const items = this.$store.getters.settings.skus.map(e => { return e.category ? e.category : 'Generic' })
      return [...new Set(items)]
    }
  },

  watch: {

    value: {
      immediate: true,
      handler(to) {
        this.formFields = to || {};
        this.formFields.tableResults = this.formFields.tableResults || [];
        let skus = this.$store.getters.settings.skus;
        (skus || []).forEach(e => {
          this.formFields.tableResults.push({
            price_per_unit: e.price_per_unit,
            code: e.code,
            description: e.description,
            unit: e.unit,
            category: e.category,
            quantity: 0,
            cost: 0,
            uuid: uuidv4(),
          });
        });
      },
    },
    formFields(to) { this.$emit("input", to); },
  },

  methods: {
    updateCost(uuid) {
      let index = this.formFields.tableResults.findIndex((e) => e.uuid === uuid);
      let item = this.formFields.tableResults[index];
      if ( item.unit === 'l') {
        item.quantity = parseFloat(item.quantity || 0);
      } else {
        item.quantity = parseInt(item.quantity || 0);
      }
      if (item.quantity<0) { item.quantity = 0; }
      item.cost = item.quantity * item.price_per_unit;
      this.formFields.total = this.formFields.tableResults.map(e => parseFloat(e.cost)).reduce((a, b) => a + b, 0)
    },
  },

  mixins: [validationRules],
};
</script>

<style scoped>
</style>
