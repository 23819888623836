<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <ActionPhone  :customer="customer" @close="$emit('closeAction')" v-if="action=='call'" />
        <ActionNok    :customer="customer" @close="$emit('closeAction')" v-if="action=='nok'"  />
        <ActionNote   :customer="customer" @close="$emit('closeAction')" v-if="action=='note'" />
        <ActionDemand :customer="customer" @close="$emit('closeAction')" v-if="action=='demand'" :instrument="instrument" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ActionPhone from '@/views/partners/ultimate_tz/components/ActionPhone';
import ActionNok from '@/views/partners/ultimate_tz/components/ActionNok';
import ActionNote from '@/views/partners/ultimate_tz/components/ActionNote';
import ActionDemand from '@/views/partners/ultimate_tz/components/ActionDemand';

export default {

  components: { ActionPhone, ActionNok, ActionNote, ActionDemand },

  props: {
    action: {
      type: String,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    instrument: {
      type: Object,
      required: false,
    }
  },

}
</script>
