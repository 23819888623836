<template>

  <v-menu bottom left offset-y :disabled="!loggedInAndLive" style="z-index: 1000;">

    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-1" icon small color="accent" v-bind="attrs" v-on="on" >
        <img v-if="isDairy"  src="@/assets/icons/profile_icon.svg" />
        <v-icon v-else>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item-group >

        <v-list-item v-if="isDairy" :to="{name: 'Home'}" style="cursor: pointer">
          <img class="mr-3"  src="@/assets/icons/home_icon.svg" />
          <v-list-item-content>
            <v-list-item-title>{{$t('Home')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isDairy" :to="{name: 'About'}" style="cursor: pointer">
          <img class="mr-3"  src="@/assets/icons/about_icon.svg" />
          <v-list-item-content>
            <v-list-item-title>{{$t('About')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
 
        <AppMenuItem v-if="!isDairy" icon="mdi-home" :to="{name: 'Home'}" :title="$t('home')" />
        <AppMenuItem icon="mdi-pine-tree" :to="{name: 'Trees'}" :title="$t('trees')" v-if="trees" />
        <AppMenuItem icon="mdi-currency-usd-circle-outline" :to="{name: 'SkuIndex'}" :title="$t('skus')" v-if="skus" />
        <AppMenuItem v-if="!isDairy" icon="mdi-source-branch" :to="{name: 'About'}" :title="$t('about')" />

        <v-list-item v-if="showLogoutIcon" @click="signOut" style="cursor: pointer">
          <img v-if="isDairy" class="mr-3" src="@/assets/icons/link_out.svg" />
          <v-icon v-else color="primary" class="mr-4" dense>mdi-logout</v-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t('logout')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>

</template>

<script>
import AppMenuItem from '@/components/AppMenuItem';
import domain from '@/mixins/domain';

export default {
  components: {
    AppMenuItem,
  },
  computed: {
    isDairy(){ return this.domain==='milk'},
    loggedInAndLive() { return this.$store.getters.venueId!==null; },
    skus() { return (this.$store.getters.settings.skus || []).filter( e => e ).length>0; },
    trees() { return (((this.$store.getters.settings.settings || {})['app'] || {})['show'] || {})['trees']; },
    isOnline() { return this.$store.getters.networkOnline; },
    pendingDeliveries() { console.log("todays deliveries: ", this.$store.getters.todaysDeliveries); return this.$store.getters.todaysDeliveries.filter(e => e.synced != true).length === 0 },
    showLogoutIcon() { return this.isOnline && this.pendingDeliveries}
  },
  methods: {
    signOut() {
      this.$store.dispatch('logout');
      // this.$router.push({name: 'Home'});
      
      // if (this.isDairy) {
      //   this.$store.dispatch('updatesignOutPopUp');
      //   console.log("hello world")
      // } else {
      //   this.$store.dispatch('logout');
      //   this.$router.push({name: 'Home'});
      // }
    },
  },
  mixins: [domain],
};
</script>


<i18n>
{
  "en": {
    "home": "Home",
    "skus": "Prices",
    "about": "About",
    "logout": "Sign Out"
  },
  "es": {
    "home": "Hogar",
    "skus": "Precios",
    "about": "Acerca de",
    "logout": "Desconectar"
  },
  "sw": {
    "home": "Nyumbani",
    "skus": "Bei",
    "about": "Kuhusu",
    "logout": "Ondoka"
  }
}
</i18n>
