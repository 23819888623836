<template>

  <v-container fluid>

    <ServerSideResult :test="theInstrument" >

      <v-row class="text-center" v-if="theInstrument">

        <v-col cols="12" md="2" sm="3">
          <Balance label="Balance" :balance="parseInt(theInstrument.balance)" :highlight="highlight" @click="balance" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <Balance label="Settlement Amount" :balance="parseInt(theInstrument.settlement_amount)" :highlight="highlight" @click="settle" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <Balance label="Min Payment to Rollover" :balance="parseInt(theInstrument.rollover_amount)" :highlight="highlight" @click="rollover" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <Balance label="Available Balance" :balance="parseInt(theInstrument.available_balance)" :highlight="highlight" @click="topup" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <Percentage label="Rate" :value="parseFloat(theInstrument.interest_rate)" :highlight="highlight" />
        </v-col>
        <v-col cols="12" md="2" sm="3" v-if="!theInstrument.disbursed_at">
          <Action label="Not Disbursed" >
            <v-btn dense small color="primary" :to="{name: 'UltimateTzLoanRequest', query: { uuid: this.uuid, instrumentId: theInstrument.id } }">
              <v-icon dense small>mdi-play</v-icon> Disburse
            </v-btn>
          </Action>
        </v-col>

      </v-row>

    </ServerSideResult>
  
  </v-container>

</template>

<script>
import lookups from '@/mixins/lookups';

import Balance from "@/views/instruments/components/Balance"
import Percentage from "@/views/instruments/components/Percentage"
import Action from "@/views/instruments/components/Action"
import ServerSideResult from "@/components/ServerSideResult";

export default {

  components: { Balance, Percentage, Action, ServerSideResult },

  // note, if you pass an instrumentId, you must also pass a customer.
  props: {
    instrument: Object,
    instrumentId: Number,
    customer: Object,
    highlight: String,
  },

  data() {
    return {
      theInstrument: undefined,
    }
  },

  computed: {
    uuid() { return (this.customer ? this.customer.uuid : this.$route.query.uuid); },
  },

  watch: {
    theInstrument(to) { this.$emit('instrument', to) },
  },

  methods: {
    balance()  { this.$router.push({ name: 'ShowCustomer',           query: { uuid: this.uuid } }) },
    settle()   { this.$router.push({ name: 'UltimateTzSettlement',   query: { uuid: this.uuid, instrumentId: this.theInstrument.id, instrumentType: this.theInstrument.type } }) },
    rollover() { this.$router.push({ name: 'UltimateTzRollover',     query: { uuid: this.uuid, instrumentId: this.theInstrument.id, instrumentType: this.theInstrument.type } }) },
    topup()    { this.$router.push({ name: 'UltimateTzTopup',        query: { uuid: this.uuid, instrumentId: this.theInstrument.id, instrumentType: this.theInstrument.type } }) },
  },

  mounted() {
    if (this.instrument) {
      this.theInstrument = this.instrument;
    } else {
      this.remoteLookup('instrumentUltimateTzSalaryAdvance', {
        uuid: this.uuid,
        id: this.instrumentId,
      }).then( instrument => this.theInstrument = instrument ).catch(e => this.theInstrument = null);
    }
  },

  mixins: [lookups],


}
</script>
