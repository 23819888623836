<template>
  <div>


  <v-card>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <Row :title="$t('fieldName')" :text="field.name" v-if="field.name" />
          <Row :title="$t('fieldName')" :text="$t('notSet')" v-else />

          <Row :title="$t('status')" v-if="field.status">
            <v-btn color="accent" small dense>{{field.status}}</v-btn>
          </Row>
          <Row :title="$t('hr.cultivationContractDate')" v-if="!!cultivationContractAssessment" >
            <Time :time="cultivationContractAssessment.formFields.contractDate.answer" format="date" />
          </Row>

          <Row :title="$t('hr.fieldCode')" :text="cultivationContractAssessment.formFields.fieldCode.answer"  v-if="cultivationContractAssessment" />


          <Row :title="$t('hr.cultivationContract.title')">
            <div v-if="!!cultivationContractAssessment">
              <v-btn
                  small
                  dense
                  :to="{name: 'ShowFieldContract', query: {
                    assessmentUuid: cultivationContractAssessment.assessmentUuid,
                    customerUuid: customerUuid
                     }}"
                  class="text-left my-2" style="height: auto"
              >
                <span class="text-center text-wrap" style="width: 120px; padding: 6px 0">
                  {{$t('hr.cultivationContract.viewCultivationContract')}}
                </span>
              </v-btn>
            </div>

            <v-btn
                color="success"
                v-else
                small
                dense
                :to="{name: 'FieldContractRegister', query: {
                  uuid: customerUuid,
                  fieldUuid: field.uuid
                }}"
                class="text-left my-2"
                :disabled="(!firstVisitAssessment)"
                style="height: auto"
            >
              <div class="text-center text-wrap" style="width: 120px; padding: 6px 0">
                {{$t('hr.cultivationContract.startCultivationContract')}}
              </div>
            </v-btn>

<!--            <v-btn-->
<!--                @click="clearFieldAssessments"-->
<!--            >-->
<!--              clearFieldAssessments-->
<!--            </v-btn>-->

          </Row>

<!--          todo add score-->
          <Row :title="$t('score')" v-if="field.score">
            <v-rating
              color="yellow darken-3"
              background-color="grey darken-1"
              empty-icon="$ratingFull"
              medium
              readonly
              :value="Number(field.score)"
            ></v-rating>
          </Row>

          <Row :title="$t('crop')" :text="cropLookups[field.primaryCropId]" />

          <Row :title="$t('size')" >
            <LandSize :landSize="field.fieldSize" v-if="field.fieldSize && field.fieldSize.landSize && (parseFloat(field.fieldSize.landSize) !== 0)" />
            <template  v-else>
              <span>{{$t('notSet')}}</span>:
              <strong class="red--text text--lighten-1">{{$t('hr.errors.invalidFieldSize')}}</strong>
            </template>
          </Row>

          <Row :title="$t('plants')">
            <span v-if="field.numberOfPlants">
              {{ field.numberOfPlants }}
            </span>
            <span v-else>{{$t('notSet')}}</span>
          </Row>

          <Row :title="$t('location')" :location="field.location" />


        </tbody>
      </template>
    </v-simple-table>
  </v-card>
    <template v-if="assessments.length > 0">
      <v-subheader>{{$t('submittedForm')}}</v-subheader>
      <v-card>
        <v-simple-table class="text-left">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{$t('phase')}}
              </th>
              <th class="text-left">
                {{$t('dateSubmitted')}}
              </th>
              <th class="text-left">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in assessments"
            >
              <td>{{ item.selectedPhase }}</td>
              <td>
                <Time v-if="item.createdAt" :time="item.createdAt" />
              </td>
              <td class="pa-0">

                <v-btn
                    color="primary"
                    small
                    dense
                    :to="{name: 'FieldAssessment', query: { assessmentUuid: item.assessmentUuid} }"
                    class="px-0 my-2"
                >

                  <div class="text-center text-wrap" style="width: 120px; padding: 6px 0">
                    {{$t('viewMoreDetails')}}
                  </div>
                </v-btn>

              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-card>
    </template>

  </div>
</template>


<script>

  import Row from '@/components/Row';
  import LandSize from '@/components/LandSize';
  import Time from '@/components/Time';
  import AllAssessmentsTable from "@/views/fields/AllAssessmentsTable";
  import ChooseAssessmentDialog from "@/views/fields/ChooseAssessmentDialog";
  export default {

    components: { Row, LandSize, Time, ChooseAssessmentDialog, AllAssessmentsTable },
    props: ['field', 'cultivationContractAssessment', 'firstVisitAssessment'],

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      cropLookups() { return this.$store.getters.cropLookups; },
      partnerCode() { return this.$store.getters.partnerCode; },
      allAssessments() { return this.$store.getters.assessments; },
      assessments() {
        return this.$store.getters.assessments.filter(assessment => assessment.fieldUUid === this.fieldUuid );
      },
      hr() { return ['hr_co', 'winwin_zw'].includes(this.partnerCode); },
      hrContract() { return this.hr; },
      fieldSize() { return parseFloat(this.field.fieldSize.landSize); },
    },
    methods: {
      clearFieldAssessments() {
        // todo only for testing: remove
        const foundItems = this.$store.getters.assessments.filter(assessment =>
            'selectedPhase' in assessment && assessment.fieldUUid !== this.fieldUuid
        )
        console.log('foundItems', foundItems)
        this.$store.commit('setAssessments', foundItems);
      }
    },
  }
</script>


<i18n>
{
  "en": {
    "fieldName": "Field Name",
    "status": "Status",
    "contractDetails": "Contract Details",
    "contract": "Contract",
    "score": "Score",
    "size": "Size",
    "plants": "Plants",
    "notSet": "Not Set",
    "submittedForm": "Submitted Form",
    "dateSubmitted": "Date Submitted",
    "phase": "Phase",
    "viewMoreDetails": "view more details"
  },
  "es": {
    "fieldName": "Nombre del campo",
    "status": "Estado",
    "contractDetails": "Detalles del contrato",
    "contract": "Contrato",
    "score": "Puntaje",
    "size": "Tamaño",
    "plants": "Total Plantas",
    "notSet": "No establecido",
    "submittedForm": "Formulario Enviado",
    "dateSubmitted": "Fecha Enviado",
    "phase": "Fase",
    "viewMoreDetails": "ver más detalles"
  },
  "sw": {
    "fieldName": "Jina la Shamba",
    "status": "Hali",
    "contractDetails": "Maelezo ya Mkataba",
    "contract": "Mkataba",
    "score": "Alama",
    "size": "Ukubwa",
    "plants": "Mimea",
    "notSet": "Haijawekwa",
    "submittedForm": "Fomu Iliyowasilishwa",
    "dateSubmitted": "Tarehe Iliyowasilishwa",
    "phase": "Awamu",
    "viewMoreDetails": "tazama maelezo zaidi"
  }
}
</i18n>
