<template>
  
  <PageHeader :customer="customer" :instrument="instrument">

    <template v-slot:buttons>
      <ActionButtons :customer="customer" @showAction="showAction" />
    </template>

    <v-container fluid>

      <v-row v-if="action">
        <v-col cols="12">
          <Actions :customer="customer" :instrument="instrument" :action="action" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab @click="changeTab(undefined)">{{customer.name}}</v-tab>
            <v-tab v-for="product in productsArray" :key="product.id" @click="changeTab(product.id)" >
              {{instrumentTypeToName(product.type)}}
            </v-tab>
            <v-tab @click="changeTab(undefined)">History</v-tab>
            <v-tab @click="changeTab(undefined)">Images</v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-tabs-items v-model="tab">
            <v-tab-item><Landing :customer="customer" /></v-tab-item>
            <v-tab-item v-for="product in productsArray" :key="product.id" >
              <Instrument :customer="customer" :balances="product" v-model="instruments[product.id]" />
            </v-tab-item>
            <v-tab-item><History /></v-tab-item>
            <v-tab-item><Images :customer="customer" /></v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

    </v-container>

  </PageHeader>

</template>

<script>
import lookups from '@/mixins/lookups';
import instrumentNames from '@/mixins/instrumentNames';

import PageHeader from '@/views/partners/ultimate_tz/components/PageHeader';
import Landing from '@/views/customers/partners/ultimate_tz/components/Landing';
import Images from '@/views/customers/partners/ultimate_tz/components/Images';
import Instrument from '@/views/customers/partners/ultimate_tz/components/Instrument';
import Actions from '@/views/customers/partners/ultimate_tz/components/Actions';
import ActionButtons from '@/views/customers/partners/ultimate_tz/components/ActionButtons';
import History from "@/views/instruments/History";

export default {

  components: { PageHeader, Landing, Images, History, Instrument, Actions, ActionButtons },

  data() {
    return {
      tab: null,
      products: undefined,
      action: undefined,
      instrumentId: undefined,
      instruments: {},
    }
  },

  computed: {
    uuid() { return this.$route.query.uuid; },
    customer() { return this.$store.getters.customer(this.uuid); },
    productsArray() { return ( this.products || [] ); },
    instrument() { return this.instruments[this.instrumentId]; },
  },

  watch: {},

  methods: {
    changeTab(to) {
      this.instrumentId = to;
    },
    showAction(action) {
      this.action = action;
    },
    setInstrument(to) { this.instrument = to; },
    // closeAction() {
    //   this.action = undefined;
    //     let _this = this;
    //     setTimeout(() => {
    //       _this.$refs.customerActions.refresh();
    //     }, 500);
    // }
  },

  mounted() {
    this.remoteLookup('ultimate_tz_customer_balances', { uuid: this.uuid }).then( balances => this.products = balances.balances ).catch(e => this.products = null);
  },

  mixins: [lookups, instrumentNames],

}
</script>
