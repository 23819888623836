<template>
  <div class="mt-2">
    <v-footer>
      <div class="footer">
        <router-link to="/dairy/collect" class="footer-tile" :class="{ active: isActiveLink('/home') }">
          <div class="icon-wrapper">
            <img
              v-if="isActiveLink('/home')"
              src="@/assets/home_active.svg"
              alt="Home"
              class="home-icon"
            />
            <img v-else src="@/assets/home.svg" alt="Home" class="home-icon" />
          </div>
          <span class="nav-text">Home</span>
        </router-link>
    
        <router-link
          to="/dairy/input_orders"
          class="footer-tile"
          :class="{ active: isActiveLink('/dairy/input_orders') }"
        >
          <div class="icon-wrapper">
            <img
              v-if="isActiveLink('dairy/input_orders')"
              src="@/assets/input_orders_active.svg"
              alt="Farmers"
              class="farmers-icon"
            />
    
            <img v-else src="@/assets/input_orders.svg" alt="Input" class="farmers-icon" />
          </div>
          <span class="nav-text">Inputs</span>
        </router-link>
      </div>
    </v-footer>
  </div>
</template>

<script>
  import domain from '@/mixins/domain';
  export default {
    methods: {
      isActiveLink(path) {
        if (path == '/home' && this.$route.path == '/dairy/collect') {
          return true
        } else {
          return this.$route.path.includes(path)
        }
      }
    },
    mixins: [domain],
  }
</script>

<style scoped>
.footer {
  /* z-index: 10000; */
  border-top: 1px solid #ced3d9;
  display: flex;
  justify-content: space-around;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 74px; /* Increased height */
  color: black;
}

.footer-tile {
  width: 120px;
  margin-top: 3px;

  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column; /* Icons below text */
  justify-content: space-between;
  align-items: center;
}

.footer-tile.active {
  padding: 7px;
  margin-top: 3px;
  border-top: 3px solid #008768;
}
.icon-wrapper {
  margin-bottom: 4px; /* Space between icon and text */
}

.farmers-icon {
  width: 24px; /* Adjust the width as needed */
  height: 24px; /* Adjust the height as needed */
}

.home-icon {
  width: 18px; /* Adjust the width as needed */
  height: 18px; /* Adjust the height as needed */
}

.nav-link {
  text-decoration: none;
  color: inherit;
}

.nav-text {
  color: #5c6c80;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
}
</style>