export default {

  methods: {
    instrumentTypeToName(instrumentType) {
      let r = instrumentType;
      if (instrumentType=='InstrumentUltimateTzSalaryAdvance') { r = 'Salary Advance' }
      if (instrumentType=='InstrumentUltimateTzCheckMeBack') { r = 'Check Me Back' }
      return r;
    }
  },

}