<template>

  <v-row>

    <v-col cols="12" sm="6">
      <v-card class="text-left" style="width: 100%;">
        <v-card-text>
          <p class="text-h4 text--primary">
            Quote Parameters
          </p>
          <v-container fluid class="text-h5">
            <v-row>
              <v-col>Cash</v-col>
              <v-col><CurrencyInput v-model="cash" /></v-col>
            </v-row>
            <v-row>
              <v-col>EFT</v-col>
              <v-col><CurrencyInput v-model="eft" /></v-col>
            </v-row>
            <v-row>
              <v-col>Mobile Money</v-col>
              <v-col><CurrencyInput v-model="mobileMoney" /></v-col>
            </v-row>
            <v-row>
              <v-col>Principal</v-col>
              <v-col>
                <ServerSideResult :test="maxPrincipal">
                  <Currency v-model="minPrincipal" />
                  to
                  <Currency v-model="maxPrincipal" />
                </ServerSideResult>
              </v-col>
            </v-row>
            <v-row>
              <v-col>Installments</v-col>
              <v-col>
                Repay on day {{ installmentDay }} of the month.
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="6">
      <v-card class="text-left" style="width: 100%;">
        <v-card-text>
          <p class="text-h4 text--primary">
            Summary
          </p>
          <v-container fluid class="text-h5">
            <v-row :class="{'red--text': (principal>maxPrincipal)}">
              <v-col>New Principal</v-col>
              <v-col><Currency v-model="principal" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Nett Disbursement</v-col>
              <v-col><Currency v-model="pricing.netDisbursement" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Interest</v-col>
              <v-col><Currency v-model="pricing.interest" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Total Repayable</v-col>
              <v-col><Currency v-model="pricing.total_repayable" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Repay On</v-col>
              <v-col><Time :time="pricing.repay_at" format="d MMM yyyy" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Monthly Interest</v-col>
              <v-col>{{pricing.interest_rate*100.0}} %</v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-btn color="success" style="width: 100%;" :disabled="!valid" @click="$emit('agreed')">
        Submit
      </v-btn>
    </v-col>

  </v-row>



</template>

<script>
import lookups from '@/mixins/lookups';

import Time from '@/components/Time';
import CurrencyInput from "@/components/CurrencyInput";
import TextInput from "@/components/TextInput";
import Currency from "@/components/Currency";
import ServerSideResult from "@/components/ServerSideResult";

export default {

  components: { Time, CurrencyInput, TextInput, Currency, ServerSideResult },

  props: ['value', 'customer', 'loanParameters'],

  data() {
    return {
      mobileMoney: 0,
      cash: 0,
      eft: 0,
      minPrincipal: 50000,
      pricing: undefined,
      serverPricing: undefined,
    }
  },

  computed: {
    maxPrincipal() { return ((this.loanParameters || {}).limit || 0); },

    principal() { return parseInt(this.mobileMoney) + parseInt(this.cash) + parseInt(this.eft) },
    netDisbursement() { return this.principal*(1-this.interest_rate) },
    interest() { return this.principal*this.interest_rate; },
    repayable() { return this.principal; },
    installment() { return this.repayable / this.installments },
    installmentDay() { return this.customer.employer.payload.payrollDay; },
    valid() {
      let r = true;
      if (r) { r = (this.principal >= this.minPrincipal); }
      if (r) { r = !!this.maxPrincipal; }
      if (r) { r = (this.principal <= this.maxPrincipal); }
      return r;
    },

    results() {
      let r = undefined;
      if (this.valid && this.pricing) {
        r = {
          uuid: this.customer.uuid,
          principal: this.pricing.principal,
          interest_rate: this.pricing.interest_rate,
          repay_at: this.pricing.repay_at,
          cash: this.cash,
          eft: this.eft,
          mobileMoney: this.mobileMoney,
        }
      }
      return r;
    },
  },

  watch: {
    principal(to) {
      this.setPricing();
    },
    serverPricing(to) {
      let e = (to || {});
      this.pricing = {
        principal: e.principal,
        limit: e.limit,
        allowed: e.allowed,
        interest_rate: e.interest_rate,
        netDisbursement: e.netDisbursement,
        interest: e.interest,
        total_repayable: e.total_repayable,
        repay_at: e.repay_at,
      }
    },
    results: {
      deep: true,
      handler(to) {
        this.$emit('input', to);
      }
    },

  },

  methods: {
    setPricing() {
      if (this.valid) {
        this.remoteLookup('ultimateTzSalaryAdvancePricing', { uuid: this.customer.uuid, principal: this.principal }).then( pricing => this.serverPricing = pricing ).catch(e => this.pricing = null);
      } else {
        this.pricing = undefined;
      }   
    },
  },

  mounted() {
    this.cash = this.maxPrincipal;
    this.setPricing();
  },

  mixins: [lookups],

}
</script>
