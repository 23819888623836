<template>
  <v-stepper v-model="step" :alt-labels="!vertical" :non-linear="!vertical" style="width:100%;" :vertical="vertical">
    <div v-if="vertical">
      <div v-for="(tab, index) in tabs" :key="index">
        <v-stepper-step
          :step="index+1"
          :editable="!disabled.includes(tab)"
          :complete="complete.includes(tab)"
          :rules="[() => !error.includes(tab)]"
          :edit-icon="(invalid.includes(tab) ? 'mdi-format-list-checks' : 'mdi-check')"
          :color="(complete.includes(tab) ? 'green' : 'primary')"
          >
          {{ captions[tab] || tab }}
        </v-stepper-step>
        <v-stepper-items>
          <v-stepper-content :step="index+1" style="width: 100%"><slot :name=tab ></slot></v-stepper-content>
        </v-stepper-items>
      </div>
    </div>
    <div v-else>
      <v-stepper-header>
        <v-stepper-step
            v-for="(tab, index) in tabs"
            :key="index"
            :step="index+1"
            :editable="!disabled.includes(tab)"
            :complete="complete.includes(tab)"
            :rules="[() => !error.includes(tab)]"
            :edit-icon="(invalid.includes(tab) ? 'mdi-format-list-checks' : 'mdi-check')"
            :color="(invalid.includes(tab) ? 'warning' : 'primary')"
          >
          {{ captions[tab] || tab }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content v-for="(tab, index) in tabs" :key="index" :step="index+1" style="width: 100%"><slot :name=tab></slot></v-stepper-content>
      </v-stepper-items>
    </div>
  </v-stepper>
</template>

<script>

export default {

  props: {
    tabs: {
      type: Array,
      required: true,
    },
    captions: {
      type: Object,
      default() { return {} },
    },
    complete: {
      type: Array,
      default() { return [] },
    },
    disabled: {
      type: Array,
      default() { return [] },
    },
    error: {
      type: Array,
      default() { return [] },
    },
    invalid: {
      type: Array,
      default() { return [] },
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      step: 1,
    }
  },

  watch: {
    step(to) {
      this.$emit('input', to);
      let position = 'middle';
      if (to==1) { position = 'first' };
      if (to==this.tabs.length) { position = 'last' };
      this.$emit('position', position );
    },
  },

  methods: {
    nextStep() {
      let current = this.step;
      while (true) {
        if (current<this.tabs.length) {
          let tab = this.tabs[current];
          current = current + 1;
          if (!this.disabled.includes(tab)) { break; }
        } else {
          current = this.step;
          break;
        }
      }
      this.step = current;
    },
    previousStep() {
      let current = this.step;
      while (true) {
        if (current>0) {
          let tab = this.tabs[current - 1];
          current = current - 1;
          if (!this.disabled.includes(tab)) { break; }
        } else {
          current = this.step;
          break;
        }
      }
      this.step = current;
    }
  },

}
</script>

<style scoped lang="scss">
  
  .v-application--is-ltr .v-stepper--vertical .v-stepper__content {
    margin: 1px 10px;
    padding: 1px 15px 1px 4px;
    }
  
</style>
