<template>
  <v-form ref="documentsForm" v-model="valid" class="text-left" >

    <v-row class="mb-2">

      <v-col cols="12" md="3">
        <v-sheet elevation="2" outlined class="pa-2">
          <div class="text-h4 mb-2">Payslips</div>
          <div>
            <PhotoInput v-model="customer.payslip_one" type="payslip" :typeId="customer.uuid" label="Current Payslip" width="100%" />
            <PhotoInput v-model="customer.payslip_two" type="payslip" :typeId="customer.uuid" label="Previous Payslip" width="100%" />
          </div>
        </v-sheet>
      </v-col>

      <v-col cols="12" md="3">
        <v-sheet elevation="2" outlined class="pa-2">
          <div class="text-h4 mb-2">Banking</div>
          <div>
            <v-btn color="primary" class="mb-4" width="100%" :disabled="!(customer.branch_name) || !(customer.account_number)" :href="`${domain}/ultimate_tz/documents/direct_debit/${uuid}?${qs}`" target="_blank">
              <v-icon>mdi-printer</v-icon> Print Direct Debit
            </v-btn>
            <PhotoInput v-model="customer.bank_statement" type="bank_statement" :typeId="customer.uuid" label="Bank Statement" width="100%" />
            <PhotoInput v-model="customer.debit_orders"   type="direct_debits"  :typeId="customer.uuid" label="Direct Debit" width="100%" />
            <PhotoInput v-model="customer.bank_other_1"   type="bank_statement" :typeId="customer.uuid" label="Other" width="100%" />
            <PhotoInput v-model="customer.bank_other_2"   type="bank_statement" :typeId="customer.uuid" label="Other" width="100%" />
          </div>
        </v-sheet>
      </v-col>

      <v-col cols="12" md="3">
        <v-sheet elevation="2" outlined class="pa-2">
          <div class="text-h4 mb-2">Identity Cards</div>
          <div>
            <PhotoInput v-model="customer.id_card_front" type="id_card_front" :typeId="customer.uuid" label="National Identity Card Front" width="100%" />
            <PhotoInput v-model="customer.id_card_back" type="id_card_back" :typeId="customer.uuid" label="National Identity Card Back" width="100%" />
            <PhotoInput v-model="customer.work_id" type="work_id" :typeId="customer.uuid" label="Work ID Card" width="100%" />
          </div>
        </v-sheet>
      </v-col>

      <v-col cols="12" md="3">
        <v-sheet elevation="2" outlined class="pa-2">
          <div class="text-h4 mb-2">Other</div>
          <div>
            <PhotoInput v-model="customer.selfie" type="selfie" :typeId="customer.uuid" label="Photo" width="100%" class="mb-2" />
            <DocumentInput v-model="customer.crb_scan" type="credit_check" :typeId="customer.uuid" label="CRB Assessment" />
          </div>
        </v-sheet>
      </v-col>

    </v-row>


    <v-row>
      <v-col v-for="image in attachedPhotos" :key="image.uuid" cols="1" >
        <v-card>
          <SingleImage :image="image" maxHeight="200px" />
        </v-card>
      </v-col>
    </v-row>

  </v-form>

</template>

<script>
import prodDebug from "@/mixins/prodDebug";

import ImageDb from '@/store/ImageDb';

import DocumentInput from "@/components/DocumentInput";
import PhotoInput from "@/components/PhotoInput";

import SingleImage from "@/components/SingleImage";
import DateInput from "@/components/DateInput";

export default {

  components: { DocumentInput, PhotoInput, SingleImage, DateInput },

  props: ['value'],

  data() {
    return {
      customer: undefined,
      valid: false,
      attachedPhotos: [],
      attachedDocuments: [],
      files: [],
    }
  },

  computed: {
    uuid() { return this.customer.uuid; },
    qs() {
      let other = {
        bank: this.customer.bank_name,
        branch_name: this.customer.branch_name,
        account_number: this.customer.account_number,
      }
      return new URLSearchParams( { ...this.loanRequest, ...other } ).toString();
    },
    domain() { return (this.production ? 'https://connect.sasa.solutions' : 'http://connect.sasa.local:3000'); },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.customer = to;
      }
    },
    customer(to) { this.$emit('input', to); },
    valid(to) { this.$emit('valid', to); },
  },

  methods: {
    validate() {
      this.$refs.documentsForm.validate();
    },
    saveImage(uuid, file) {
      const imageToUpload = { type: 'images', type_id: this.uuid, uuid: uuid, file: file }
      this.attachedPhotos.push(imageToUpload)
      this.$store.dispatch('addImage', imageToUpload);
    },
    upload() {
      this.files.forEach( file => {
        // let url = URL.createObjectURL(file);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const _this = this;
        reader.onload = function () {
          let uuid = ImageDb.add(reader.result);
          const fileToUpload = { type: 'documents', type_id: this.uuid, uuid: uuid, file: file }
          _this.attachedDocuments.push(fileToUpload)
          _this.$store.dispatch('addImage', fileToUpload);
        }
      });
    }
  },

  mixins: [prodDebug],

}
</script>
