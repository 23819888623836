<template>
  <v-btn
    :style="buttonStyle"
    :disabled="disabled"
    @click="$emit('click')"
  >
    Submit
  </v-btn>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
  computed: {
    buttonStyle() {
      return { 
        width: '100%',
        height: '50px',
        borderRadius: '8px',
        background: this.disabled ? '#CED3D9' : '#008768',
        color: 'white', // Text color
      };
    }
  },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
