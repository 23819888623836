<template>
  
  <v-form ref="settlementForm" v-model="valid" style="width: 100%;">
    <v-container fluid>

      <ServerSideResult :test="instrument" v-if="disbursing">
        <v-row>
          <v-col cols="12">
            <InstrumentUltimateTzSalaryAdvance :instrument="instrument" highlight="Settlement Amount" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <CurrencyInput v-model="cash" label="Cash Received" />
          </v-col>
          <v-col cols="6">
            <TextInput v-model="cashReceiptNumber" label="Receipt Number" required />
          </v-col>
        </v-row>


        <v-row>
          <v-col>
            <v-btn @click="settlement" color="primary" :disabled="!maySettle">Settle Loan</v-btn>
          </v-col>
        </v-row>

      </ServerSideResult>

      <ServerSideResult :test="settlementProcess" v-else>
        <v-row>
          <v-col cols="12" class="text-h4 text--primary text-left">
            Thank you. Your settlement has been processed.
          </v-col>
        </v-row>
      </ServerSideResult>

    </v-container>
  </v-form>

</template>

<script>
import lookups from '@/mixins/lookups';

import CurrencyInput from "@/components/CurrencyInput";
import TextInput from "@/components/TextInput";
import Currency from "@/components/Currency";
import ServerSideResult from "@/components/ServerSideResult";
import InstrumentUltimateTzSalaryAdvance from "@/views/instruments/InstrumentUltimateTzSalaryAdvance"


export default {

  components: { CurrencyInput, TextInput, Currency, ServerSideResult, InstrumentUltimateTzSalaryAdvance },

  props: ['customer', 'instrumentId', 'instrument'],

  data() {
    return {
      valid: false,
      cash: 0,
      cashReceiptNumber: undefined,
      disbursing: true,
      settlementProcess: undefined,
    }
  },

  computed: {
    settlementAmount() { return ((this.instrument.settlement_amount || 0)/100.0); },
    maySettle() { return this.valid && (this.cash>=0) && ( (this.instrument) && (this.cash<=this.settlementAmount ) ) },
  },

  watch: {
    instrument(to) {
      if (to && this.cash==0) {
        this.cash = this.settlementAmount;
      }
    }
  },

  methods: {
    validate() { this.$refs.settlementForm.validate(); },
    settlement() {
      this.disbursing = false;
      this.remoteLookup('instrumentUltimateTzSalaryAdvanceSettlement', {
        uuid: this.customer.uuid,
        id: this.instrumentId,
        cash: this.cash,
        receiptNumber: this.cashReceiptNumber,
      }).then( e => this.settlementProcess = 'done' ).catch(e => this.settlementProcess = null);
    },
  },

  mixins: [lookups],

}
</script>
