<template>

  <v-row>
    <v-col cols="12">
      <ServerSideResult :test="done" />
    </v-col>
  </v-row>

</template>

<script>
import lookups from '@/mixins/lookups';

import ServerSideResult from "@/components/ServerSideResult";

export default {

  components: { ServerSideResult },

  props: ['customer'],

  data() {
    return {
      done: undefined
    }
  },

  watch: {
    done(to) {
      if (to) {
        this.$emit('done', to);
      }
    }
  },

  mounted() {
    this.remoteLookup('ultimateTzCheckMeBackDisbursed', { uuid: this.customer.uuid }).then( done => this.done = done ).catch(e => this.done = null);
  },

  mixins: [lookups],

}
</script>
