<template>
  <FieldHeader
      :customer="customer"
      :field="field"
      :cultivationContractAssessment="cultivationContractAssessment"
      v-if="field" >
    <v-main>


    <v-row no-gutters v-masonry>

      <v-col cols="12" class="pa-1">
        <FieldBasics
            :field="field"
            :cultivationContractAssessment="cultivationContractAssessment"
            :firstVisitAssessment="firstVisitAssessment"
        />
      </v-col>

      <v-col cols="12" class="pa-1" v-if="edit">
        <v-textarea :label="$t('notes')" v-model="field.note" auto-grow outlined></v-textarea>
      </v-col>
      <v-col cols="12" class="pa-1" v-if="!edit && field.note">
        {{field.note}}
      </v-col>

      <v-col cols="12" class="pa-1" v-if="showLocation">
        <Map :locations="locations" />
      </v-col>

      <v-col cols="12" class="pa-1" v-if="showPlot">
        <MapPolygons :coords="coords" height='300px' />
      </v-col>

    </v-row>

    <v-row no-gutters v-if="images.length>0">
      <v-col cols="12" class="pa-1">
        <Images :images="images" />
      </v-col>
    </v-row>
    </v-main>

  </FieldHeader>
</template>


<script>
  import fields from '@/mixins/fields';

  import Map from '@/components/Map';
  import TakePhoto from '@/components/TakePhoto';
  import Images from '@/components/Images';
  import Row from '@/components/Row';
  import LandSizeInput from '@/components/LandSizeInput';
  import MapPolygons from '@/components/MapPolygons';
  import FieldHeader from '@/views/fields/components/FieldHeader';
  import FieldBasics from '@/views/fields/components/Basics';
  import {assessmentNameEnum} from "@/views/partners/hr_co/constant";
  import SignatureModal from "@/components/SignatureComponent/SignatureModal";
  import SignatureSection from "@/components/SignatureComponent/SignatureSection";

  export default {

    components: { Map, TakePhoto, Images, Row, LandSizeInput, MapPolygons, FieldHeader, FieldBasics, SignatureModal, SignatureSection },

    data: () => ({
      edit: false,
      field: undefined,
      vueCanvas:null,
      painting:false,
      canvas:null,
      ctx:null
    }),
    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      // field() { return this.customer.fields.filter( f => f.uuid==this.uuid )[0]; },
      images() { return this.$store.getters.images.filter( e => ( e.type_id==this.fieldUuid ) && (e.type==='field') ) ; },
      locations() { return (this.field.location ? [this.field.location] : []); },
      showPlot() { return this.field.map && this.field.map.length>2; },
      showLocation() { return !this.showPlot && this.locations.length>0; },
      crops() { return this.$store.getters.crops; },
      coords() {
        return [{
          customerUuid: this.customerUuid,
          fieldUuid: this.uuid,
          caption: this.field.name,
          coordinates: this.field.map,
        }];
      },
      cultivationContractAssessment() {
        return this.$store.getters.assessments.find(assessment =>
            assessment.assessmentName === assessmentNameEnum.cultivationContract &&
            assessment.customerUuid === this.customerUuid &&
            assessment.fieldUuid === this.fieldUuid

        )
      },
      firstVisitAssessment() {
        return this.$store.getters.customerFirstVisit(this.customerUuid)
      }
    },

    watch: {
      fieldUuid: {
        immediate: true,
        handler(to) {
          if (to && this.customer) {
            const field = this.customer.fields.find( f => f.uuid===to );
            if (!field) {
              this.toCustomer()
            }
            this.field = field
          } else {
            this.field = undefined;
          }
        },
      }
    },

    methods: {
      updateFieldSize(size) {
        this.field.fieldSize.landSize = size.landSize;
        this.field.fieldSize.unit = size.unit;
      },
      toCustomer() {
        this.$router.push({
          name: 'ShowCustomer',
          query: { uuid: this.customerUuid }
        });
      },
      saveImage(uuid) {
        console.log('saveImage', uuid)
      },
      toggleEdit() { this.edit = true; },
      upsert() {
        this.edit = false;
        // console.log(this.field);
        // console.log(this.deriveFieldName(this.field));
        this.field.name = this.deriveFieldName(this.field);
        this.$store.dispatch('upsertCustomer', this.customer);
      },
    },

    mixins: [fields],

  }
</script>

<style>

</style>


<i18n>
{
  "en": {
    "notes": "Notes"
  },
  "es": {
    "notes": "Notas"
  },
  "sw": {
    "notes": "Maelezo"
  }
}
</i18n>
