<template>
  <v-form ref="nextOfKinForm" v-model="valid" class="text-left" >

    <v-row>
      <v-col cols="12" md="2">
        <h2 v-if="customer.demographic.marital_status">Spouse</h2>
        <v-select v-else :items="['Brother', 'Sister', 'Mother', 'Father', 'Cousin', 'Child']" label="Relationship" v-model="kin.relationship" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="1">
        <TitleInput v-model="kin.title" required />
      </v-col>
      <v-col cols="12" sm="4">
        <NameInput v-model="kin.first_name" :label="$t('First Name')" required />
      </v-col>
      <v-col cols="12" sm="3">
        <NameInput v-model="kin.middle_name" :label="$t('Middle Name')" />
      </v-col>
      <v-col cols="12" sm="4">
        <NameInput v-model="kin.surname" :label="$t('Surname')" required />
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" md="6">
        <MsisdnInput v-model="kin.msisdn" required />
      </v-col>
      <v-col cols="12" md="6">
        <MsisdnInput v-model="kin.alternative_msisdn" label="Alternate Telephone" />
      </v-col>
    </v-row>

  </v-form>

</template>

<script>
import MsisdnInput from "@/components/MsisdnInput";
import NameInput from "@/components/NameInput";
import TitleInput from "@/components/TitleInput";

export default {

  components: { NameInput, MsisdnInput, TitleInput },

  props: ['value'],

  data() {
    return {
      customer: undefined,
      valid: false,
    }
  },

  computed: {
    kin() { return this.customer.nextOfKin; },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.customer = to;
      }
    },
    customer(to) { this.$emit('input', to); },
    valid(to) { this.$emit('valid', to); },
  },

  methods: {
    validate() {
      this.$refs.nextOfKinForm.validate();
    },
  },

}
</script>
