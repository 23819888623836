<template>
  <v-container>
     <div>
        <v-btn @click='connect_to_printer'>
          connect to printer
        </v-btn>
        <v-btn
          class="submit-n-print"
          :class="valid ? 'enabled-button' : 'disabled-button'"
          @click="printReceipt"
          block
        >
          Submit and print
        </v-btn>
      </div>
  </v-container>
</template>

<script>
  export default {
    data: {
      characteristic: null,
      valid: false
    },

    methods: {
      async connect_to_printer() {
        try {
          if (!this.characteristic) {
            const device = await navigator.bluetooth.requestDevice({
              filters: [{
                services: ['000018f0-0000-1000-8000-00805f9b34fb'] // Replace with your printer's service UUID
              }]
            });

            const server = await device.gatt.connect();
            const service = await server.getPrimaryService('000018f0-0000-1000-8000-00805f9b34fb'); // Replace with your printer's service UUID
            this.characteristic = await service.getCharacteristic('00002af1-0000-1000-8000-00805f9b34fb'); // Replace with your printer's characteristic UUID

            // printButton.removeAttribute('disabled');
            this.valid = true
            console.log('Connected to printer.');
          } else {
            console.log('Already connected to printer.');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      },

      async printReceipt() {
        try {
          const billSections = [
            "Sample Bill\n-----------------\nItem        Price\n-----------------\nItem 1      Ksh10\n",
            "Item 2      Ksh20\nItem 3      Ksh15\n-----------------\nTotal       Ksh50\n",
            "-----------------\nSample created by Lawrence for test!\n\n"
          ];

          const encoder = new TextEncoder('utf-8');

          for (let i = 0; i < billSections.length; i++) {
            const billBytes = encoder.encode(billSections[i] + '\u000A\u000D'); // Adding line feed + carriage return
            await this.characteristic.writeValue(billBytes);
            console.log(`Section ${i + 1} of the bill sent to printer.`);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }

  }

</script>
