var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ServerSideResult',{attrs:{"test":_vm.data}},[_c('v-data-table',{staticClass:"w-100",attrs:{"dense":"","headers":_vm.headers,"items":_vm.data,"item-key":"item.uuid","search":_vm.search},scopedSlots:_vm._u([{key:"header.msisdn",fn:function(){return [_vm._v(_vm._s(_vm.$t('telephone')))]},proxy:true},{key:"item.msisdn",fn:function(ref){
var item = ref.item;
return [_c('Telephone',{attrs:{"msisdn":item.msisdn}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"value":item.amount}})]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"value":item.balance,"cents":""}})]}},{key:"item.due_at",fn:function(ref){
var item = ref.item;
return [(item.due_at)?_c('Time',{attrs:{"time":item.due_at,"format":"d MMM"}}):_vm._e()]}},{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary","to":{name: 'ShowCustomer', query: { uuid: item.uuid } }}},[_c('v-icon',{attrs:{"dense":"","small":""}},[_vm._v("mdi-account")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }