import {customDebounce} from "@/views/partners/hr_co/utils";

export default {
    data() {
      return {
          isPrintingRaw: false
      }
    },
    computed: {
      isPrinting() {
          return this.isPrintingRaw
      },
    },
    methods: {
        async printMixin() {
            this.isPrintingRaw = true
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    this.$htmlToPaper('printTarget', null,() => {
                        this.isPrintingRaw = false
                        resolve();
                    });
                }, 300);
            });
        },
    },
};
