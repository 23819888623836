var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"w-100",attrs:{"dense":"","headers":_vm.headers,"items":_vm.customers,"item-key":"item.uuid","search":_vm.search},scopedSlots:_vm._u([{key:"item.msisdn",fn:function(ref){
var item = ref.item;
return [_c('Telephone',{attrs:{"msisdn":item.msisdn}})]}},{key:"item.isAFarmer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.incomeDetails || {}).fcsCardNumber)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.nileUgApplication)?_c('v-icon',{attrs:{"dense":"","small":"","color":"green"}},[_vm._v("mdi-check")]):_c('Percentage',{attrs:{"value":(item.lastNileSubmissionPage || 1)/8.0}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary","to":{name: 'ShowCustomer', query: { uuid: item.uuid } },"outlined":!item.nileUgApplication}},[_c('v-icon',{attrs:{"dense":"","small":""}},[_vm._v("mdi-account")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary","to":{name: 'NileRegisterContact', query: { uuid: item.uuid } },"outlined":item.nileUgApplication}},[_c('v-icon',{attrs:{"dense":"","small":""}},[_vm._v("mdi-text-account")])],1)]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"value":_vm.balances.sbg_credit[item.uuid] || 0}})]}},{key:"item.doNotLend",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"value":_vm.balances.agri[item.uuid] || 0}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }