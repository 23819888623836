<template>

  <Value :label="label" :elevation="elevation" :color="color" :rounded="rounded" :highlight="highlight" :width="width" v-bind="$attrs" v-on="$listeners">
    <Percentage :value="value" :decimals="decimals" />
  </Value>

</template>

<script>
import Value from "@/views/instruments/components/Value"
import Percentage from "@/components/Percentage"

export default {

  components: { Value, Percentage },

  props: {
    value: Number,
    label: String,
    highlight: [String, Boolean],
    cents: {
      type: Boolean,
      default: true,
    },
    width: String,
    color: String,
    elevation: {
      type: Number,
      default: 2,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    decimals: {
      type: Number,
      default: 1,
    },
  },

}
</script>
