<template>
    <v-form  v-if="sbg" class="login-form"  v-model="validForm">
      <div v-if="sbg">
        <div class="otp-input-container">
          <p class="enter-pin">Please enter the PIN</p>
          <v-otp-input
            required
            v-model="otp"
            :rules="otpRules"
            type="password"
            rounded="true"
          ></v-otp-input>
        </div>        
      </div>
      <div class="show-help" v-if="showHelp">
        <p>No PIN received? We sent the PIN to 0849993016. Is this the right phone number or email address? If it is right, maybe wait a little longer. Otherwise, restart using the link below.</p>
        <div class="request-pin-link">
          <a href="#" @click="requestAnotherPin">Request another pin</a>
        </div>
      </div>
      <div class="button-container">
        <Button 
        :class="validForm ? 'enabled-button' : 'disabled-button'"
        @click="$emit('input', otp);"
        />
      </div>
    </v-form>
    <v-form v-else v-model="validForm">
      <div class="mb-4 font-weight-light">{{$t('message')}}</div>
      <div>
        <v-text-field placeholder="123456" v-model="otp" :rules="otpRules" type="password" ></v-text-field>
      </div>
      <div align="right">
        <v-btn type="submit" @click="$emit('input', otp);" :disabled="!validForm">
          {{$t('go')}}
        </v-btn>
      </div>
      <div class="mt-3" align="center" v-if="showHelp">
        {{$t('noPin')}} {{$t('sentTo')}} {{identifier}}. {{$t('help')}}
      </div>
    </v-form>
</template>

<script>

import Logo from '@/components/HeaderBarLogo';
import Button from '@/components/Button';

export default {
  components: { Logo, Button },
  props: ['identifier'],
  data: () => ({
    validForm: false,
    otpDigits: ['', '', '', '', ''],
    otpDigitRules: [
        v => !!v || 'Digit is required',
        v => (v && /^\d$/.test(v)) || 'Enter a single digit',
      ],
    otp: null,
    showHelp: false,
    otpRules: [
      v => /^\d{6}$/.test(v)
    ],
  }),
  mounted: function () {
    window.setInterval(() => { this.showHelp = true; }, 15000)
  },
  computed: {
    sbg() { return true }
  },
  methods: {
    requestAnotherPin() {
      console.log('Requesting another PIN...');
    },
  },

};
</script>

<style lang="scss" scoped>
.login-form {
  display: flex;
  flex-direction: column;
  height: 62vh;
  width: 100%;
  margin-top: 60px;
}

.otp-input-container{
  width: 100%;
  margin: 0 auto;
}

.enabled-button {
  background: #008768 !important; 
  color: var(--primary-blue, #FFFFFF);
}

.disabled-button {
  background: var(--neutrals-pale-grey, #CED3D9) !important;
  color: #5C6C80 !important;
  pointer-events: none;
}

.enabled-button, .disabled-button{
  font-size: 14px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 16px;
}

.button-container {
  display: flex;
  align-items: flex-end;
  margin-top: auto; /* Push it to the bottom */
}

.show-help{
  p, a {
    color: #007D85;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    line-height: 20px;
  }
  width: 90%;
  margin-top: 50px;
}

.verification-text {
  color: var(--neutrals-medium-grey-text-body, #1A314D);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
</style>

<i18n>
{
  "en": {
    "message": "We have sent you a PIN code. Please enter this below.",
    "go": "Go",
    "noPin": "No PIN received?",
    "sentTo": "We sent the PIN to",
    "help": "Is this the right phone number or email address? If it is right, maybe wait a little longer. Otherwise, restart using the link below."
  },
  "es": {
    "message": "Le hemos enviado un código PIN. Por favor ingrese esto a continuación.",
    "go": "Ir",
    "noPin": "¿No recibió PIN?",
    "sentTo": "Enviamos el PIN al",
    "help": "¿Es este el número de teléfono o la dirección de correo electrónico correctos? Si es correcto, quizás espere un poco más. De lo contrario, comience de nuevo utilizando el enlace a continuación."
  },
  "sw": {
    "message": "Tumekutumia nambari ya siri. Tafadhali ingiza hii hapa chini.",
    "go": "Nenda",
    "noPin": "Hakuna PIN iliyopokelewa?",
    "sentTo": "Tulituma PIN hiyo kwa",
    "help": "Hii ni nambari sahihi ya simu au anwani ya barua pepe? Ikiwa ni sawa, labda subiri kidogo. Vinginevyo, anza kutumia kiunga hapa chini."
  }
}
</i18n>
