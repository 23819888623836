<template>
  <v-form v-model="validForm" ref="contactForm">
    <v-container>

      <v-row class="blue lighten-5">
        <v-col cols="12" sm="6">
          <MsisdnInput v-model="customer.msisdn" />
        </v-col>
        <v-col cols="12" sm="6">
          <EmailInput v-model="customer.email" />
        </v-col>
      </v-row>

      <v-row class="blue lighten-5">
        <v-col cols="12" sm="6">
          <NameInput v-model="customer.firstName" :label="$t('First Name')" />
        </v-col>

        <v-col cols="12" sm="6">
          <NameInput v-model="customer.surname" :label="$t('Surname')" />
        </v-col>
      </v-row>

      <v-row class="red lighten-5">
        <RegionPicker v-model="customer.regions" />
      </v-row>


      <v-row class="green lighten-5">

        <v-col cols="12" sm="6" lg="3">
          <v-select :items="crops" :label="$t('Primary Crop')" class="text-left" v-model="customer.primaryCrop"></v-select>
        </v-col>        

      </v-row>


      <v-row class="blue-grey lighten-5">
        <v-col cols="12">
          <v-textarea
            :label="$t('Notes')"
            v-model="customer.note"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <div class="text-left">
              <v-btn @click="$emit('clearInputs')" class="d-none d-sm-inline">{{$t('Clear')}}</v-btn>
            </div>
            <div class="text-right">
              <v-btn @click="$emit('submitAndEdit')" color="primary" :disabled="!validForm" class="mr-2">{{$t('SaveAndViewDetail')}}</v-btn>
              <v-btn @click="$emit('submitAndNew')" color="primary" :disabled="!validForm">{{$t('SaveAndAddNew')}}</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </v-form>
</template>


<script>

  import RegionPicker from '@/components/RegionPicker';
  import MsisdnInput from '@/components/MsisdnInput';
  import EmailInput from '@/components/EmailInput';
  import NameInput from '@/components/NameInput';
  import DateInput from '@/components/DateInput';

  export default {

    components: {
      RegionPicker,
      MsisdnInput,
      EmailInput,
      NameInput,
      DateInput,
    },

    props: ['customer'],

    data: () => ({
      validForm: false,
    }),


    computed: {
      crops() { return this.$store.getters.cropNames; },
    },
    methods: {
      resetForm() {
        this.$refs.contactForm.reset()
      }
    }
  }
</script>


<i18n>
{
  "en": {
    "Primary Crop": "Primary Crop",
    "First Name": "First Name",
    "Surname": "Surname",
    "Notes": "Notes",
    "SaveAndEdit": "Save & Edit",
    "SaveAndAdd": "Save & Add",
    "Clear": "Clear"
  },
  "es": {
    "Primary Crop": "Variedad",
    "First Name": "Primer nombre",
    "Surname": "Apellido",
    "Notes": "Notas",
    "SaveAndEdit": "Salvar o Editar",
    "SaveAndAdd": "Salvar y añadir (Otro Contacto)",
    "Clear": "Reiniciar"
  },
  "sw": {
    "Primary Crop": "Mazao ya Msingi",
    "First Name": "Jina la kwanza",
    "Surname": "Jina la jina",
    "Notes": "Noti",
    "SaveAndEdit": "Hifadhi na hariri",
    "SaveAndAdd": "Hifadhi na sajili mwingine",
    "Clear": "Futa"
  }
}
</i18n>
