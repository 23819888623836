<template>
  <v-form class="login-form"  v-model="validForm">
    <div v-if="sbg">
      <p class="verification-text">Please enter your phone number for verification.</p>
      <v-text-field 
        class="phone-input"
        label="Phone number"
        color="#00AFA5"
        v-model="identifier"
        :rules="identifierRules"
        outlined
        hint="After submitting you will receive an PIN"
        persistent-hint />
    </div>
    <div class="row">
      <div class="d-flex justify-start align-center mt-6 ml-2 col-12" v-if="domain=='milk'">
        <v-checkbox></v-checkbox>
        <div>
          I accept the <span class="green--text" @click="termsAndConditions">terms and conditions</span>
          <v-dialog
            v-model="dialog"
            persistent
            width="1024"
          >
            <v-card>
              <v-btn
                class="mx-2"
                fab
                dark
                @click="dialog = false"
              >
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <vue-pdf-embed :source="termsAndConditionsource" />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-darken-1"
                  variant="text"
                  @click="dialog = false"
                >
                  Close
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div class="col-12">
        <Button
          class="submit-btn"
          :class="validForm ? 'enabled-button' : 'disabled-button'"
          @click="submit" 
        />
      </div>
    </div>
  </v-form>
</template>

<script>

import domain from '@/mixins/domain';
import Button from '@/components/Button';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: { Button, VuePdfEmbed },
  data: () => ({
    validForm: false,
    identifier: null,
    showHelp: (self.domain=='milk'),
    placeholder: '0736527195',
    dialog: false,
    termsAndConditionsource: '/documents/dairy_ke_tsandcs.pdf',
    identifierRules: [
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || /^\d{9,}$/.test(v) || /^[\w.-@]{3,}\/\w{3,8}$/.test(v) || /^\d{9,}\/\w{3,}$/.test(v)
    ],
  }),
  computed: {
    sbg() { return true },
    partnerCode() { return this.$store.getters.settings.partnerCode; },
  },
  methods: {
    submit() { this.$emit('input', this.identifier); },
    changePlaceholder() { this.placeholder = ( this.placeholder==='you@example.com' ? '0736527195' : 'you@example.com' ); },
    termsAndConditions() { this.dialog = true },
  },
  mounted: function () {
    if (this.domain!='ultimate' && this.domain!='milk') {
      window.setInterval(() => { this.changePlaceholder() }, 3000);
    }
  },
  mixins: [domain],
};
</script>

<style scoped>
.login-form {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  height: 70vh;
}

.enabled-button {
  background: #008768 !important; 
  color: var(--primary-blue, #FFFFFF);
}

.disabled-button {
  background: var(--neutrals-pale-grey, #CED3D9) !important;
  color: #5C6C80 !important;
  pointer-events: none;
}

.enabled-button, .disabled-button{
  font-size: 14px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 16px;
}


.verification-text {
  color: var(--neutrals-medium-grey-text-body, #1A314D);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 45px;
}

.phone-input {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  border-radius: 8px; 
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}

</style>

<i18n>
{
  "en": {
    "go": "Go"
  },
  "es": {
    "go": "Ir"
  },
  "sw": {
    "go": "Nenda"
  }
}
</i18n>
