<template>
  <v-container>
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <h1 class="milk-collection">Daily Produce</h1>
      <v-btn color="info" small dense class="ma-1" to="/dairy/collect">
        <v-icon>mdi-backburger</v-icon>
      </v-btn>
    </div>
    <div>
      <template>
        <v-data-table
          :headers="headers"
          :items="todaysCollection"
          :items-per-page="5"
          class="elevation-1"
        >
        <template v-slot:item.createdAt="{ value }">
          {{ `${new Date(value).getDate()}-${new Date(value).getMonth() + 1}-${new Date(value).getFullYear()}` }}
        </template>

        <template v-slot:item.synced="{value}">
          <v-icon v-if="value" color="primary">mdi mdi-check</v-icon>
          <v-icon v-else color="primary"> mdi mdi-close </v-icon>
        </template>
      </v-data-table>
      </template>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <h1 class="milk-collection">Total Daily Produce {{ riderCollection }}</h1>
      <v-btn @click="resubmit" class="primary" v-if="pendingUpsert.length> 0 && isOnline">{{pendingUpsert.length}} pending collections</v-btn>
    </div>
  </v-container>
</template>


<script>

import lookups from "@/mixins/lookups";

export default {
  data() {
    return {
      dailyProduce: [],
      headers: [
        {
          text: 'Receipt',
          align: 'start',
          sortable: false,
          value: 'receipt_number'
        },
        { text: 'C No', value: 'farmerNo' },
        { text: 'C Name', value: 'farmerName' },
        { text: 'SES', value: 'amPm' },
        { text: 'Can No', value: 'canNumber' },
        { text: 'Date', value: 'createdAt' },
        { text: 'Qnt', value: 'weight' },
        { text: 'Sync', value: 'synced'}
      ],
      riderCollection: undefined,
      
    }
  },
  computed: {
    pendingUpsert() { return this.$store.getters.milkCollections; },
    isOnline() { return this.$store.getters.networkOnline; },
    todaysCollection() { 
      return this.$store.getters.todaysDeliveries 
    },
  },

  methods: {
    resubmit() {
      for(let submission of this.pendingUpsert) {
        this.$store.dispatch('milkCollectionUpsert', submission);
      }
    }
  },
  mounted() {
    this.remoteLookup('getDailyProduce', {})
        .then( produce => {
          this.dailyProduce = produce.delivery_data;
          this.riderCollection = produce.cumulative
        });
  },

  mixins: [lookups],
}
</script>

<style scoped>
.milk-collection{
  color: var(--pw-text-colours-gray-medium, #3C4B6C);
  text-align: left;
  font-size: 17px;
  font-style: normal;
  font-weight: 430;
  margin: 15px 0px 30px 0px;
  line-height: 16px; /* 114.286% */
}
</style>
