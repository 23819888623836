<template>
  <v-dialog
    v-model="dialog"
    width="350px"
    style="z-index: 9999"
  >
    <template v-slot:activator="{ on, attrs }">
      <span @click.stop="dialog = (true && !disabled)" :style="{ 'width': width } ">
        <slot></slot>
      </span>
    </template>

    <v-card>

      <v-img :src="url" height="300px" v-if="url"></v-img>

      <v-card-subtitle class="pt-4" v-if="!image">
        <span v-if="text">{{text}}</span>
        <span v-else>{{$t('message')}}</span>
      </v-card-subtitle>

      <v-card-actions>
        <v-file-input
            class="d-none"
            label="File input"
            outlined
            dense
            capture="user"
            accept="image/*"
            @change="cameraClick"
            ref="fileInput"
        ></v-file-input>
        <v-file-input
            class="d-none"
            label="File input"
            outlined
            dense
            accept="image/*"
            @change="imageUploaded"
            ref="fileInput2"
        ></v-file-input>

        <v-btn color="deep-purple lighten-2" text @click="triggerOpenGallery" >
          <span v-if="image">{{$t('reUpload')}}</span>
          <span v-else>{{$t('upload')}}</span>
        </v-btn>

        <v-spacer />

        <v-btn color="deep-purple lighten-2" text @click="triggerTake" >
          <span v-if="image">{{$t('retake')}}</span>
          <span v-else>{{$t('takePhoto')}}</span>
        </v-btn>

        <v-spacer />

        <v-btn color="deep-purple lighten-2" text>
          <span v-if="image" @click="save">{{$t('save')}}</span>
          <span v-else @click="cancel">{{$t('cancel')}}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import ImageDb from '@/store/ImageDb';

  export default {
    props: {
      text: String,
      disabled: {
        type: Boolean,
        default: false,
      },
      width: String,
    },

    data () {
      return {
        dialog: false,
        url: null,
        image: null,
      }
    },
    computed: {
      apiKey() { return this.$store.getters.apiKey; },
    },
    methods: {
      cameraClick(image) {
        this.image = image;
        this.url = URL.createObjectURL(image);
      },
      imageUploaded(image) {
        this.image = image;
        this.url = URL.createObjectURL(image);
      },
      triggerTake() {
        this.$refs.fileInput.$refs.input.click();
        },
      triggerOpenGallery() {
        this.$refs.fileInput2.$refs.input.click();
        },
      cancel() {
        this.dialog = false;
        this.image = null;
        this.url = null;
      },
      save() {
        const _this = this;
        let reader = new FileReader();
        reader.readAsDataURL(this.image);
        reader.onload = function () {
          let uuid = ImageDb.add(reader.result);
          _this.$emit('click', uuid);
          _this.image = null;
          _this.url = null;
          _this.dialog = false;
        };
      },
    },
  }
</script>


<i18n>
{
  "en": {
    "message": "Use your camera to take a photo. Then, if you are happy with the photo, click save. It will be stored locally and uploaded when you next have an internet connection.",
    "retake": "Retake",
    "reUpload": "Re upload",
    "upload": "Upload",
    "takePhoto": "Take Photo"
  },
  "es": {
    "message": "Usa tu cámara para tomar una foto. Luego, si está satisfecho con la foto, haga clic en guardar. Se almacenará localmente y se cargará la próxima vez que tenga una conexión a Internet.",
    "retake": "Retomar",
    "reUpload": "Volver a subir",
    "upload": "Subir",
    "takePhoto": "Tomar Foto"
  },
  "sw": {
    "message": "Tumia kamera yako kuchukua picha. Kisha, ikiwa unafurahi na picha hiyo, bonyeza kuokoa. Itahifadhiwa kienyeji na kupakiwa wakati ujao una unganisho la mtandao.",
    "retake": "Kurudia",
    "reUpload": "Pakia tena",
    "upload": "Kupakia",
    "takePhoto": "Piga Picha"
  }
}
</i18n>

