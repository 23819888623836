<template>
  <v-img :src="src"
         :max-width="this.maxHeight"
         v-bind:height="height"
         @load="$redrawVueMasonry()"></v-img>
</template>

<script>
  import ImageDb from '@/store/ImageDb';

  export default {
    props: ['image', 'maxHeight', 'height'],

    data: () => ({
      src: undefined,
    }),

    watch: {
      image: {
        immediate: true,
        handler(to) {
          if (typeof(to)==='string') {
            this.src = to;
          } else if (!!to && to.uuid) {
            ImageDb.url(to.uuid).then( e => this.src = e );
          }
        }
      },
    }
  }
</script>