<template>
  <PageHeader title="New Expense" icon="mdi-cash-register" validate @validate="validate">
    <template v-slot:buttons>
      <v-btn color="primary" small dense class="ma-1" :to="{ name: 'UltimateTzCashbox' }"><v-icon dense>mdi-piggy-bank-outline</v-icon> Cashbox</v-btn>
    </template>

    <v-form v-model="valid" ref="expenseForm" >
    
      <v-container fluid>

        <v-row>
          <v-col cols="4">
            <Widget title="Expense">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <SelectInput :items="expenseTypes" single-line v-model="expenseType" required />
                  </v-col>
                  <v-col cols="12">
                    <CurrencyInput v-model="amount" required />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="notes" label="Notes" solo />
                  </v-col>
                </v-row>
              </v-container>
            </Widget>
          </v-col>

          <v-col cols="4">
            <Widget title="Supporting Documentation">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <PhotoInput v-model="invoice" type="transactional" label="Receipt" />
                  </v-col>
                  <v-col cols="12">
                    <DocumentInput v-model="confirmation" type="transactional" label="Email Confirmation" />
                  </v-col>
                </v-row>
              </v-container>
            </Widget>
          </v-col>

          <v-col cols="4">
            <Widget title="">
              <FingerprintAuthorisation v-model="authorisation" user />
            </Widget>
          </v-col>

        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn color="primary" block :disabled="!valid" @submit="submit">Submit</v-btn>
          </v-col>
        </v-row>

      </v-container>
    </v-form>

  </PageHeader>

</template>

<script>
import lookups from '@/mixins/lookups';

import PageHeader from "@/components/PageHeader";
import SelectInput from "@/components/SelectInput";
import CurrencyInput from "@/components/CurrencyInput";
import PhotoInput from "@/components/PhotoInput";
import DocumentInput from "@/components/DocumentInput";
import Widget from "@/components/Widget";
import FingerprintAuthorisation from '@/components/FingerprintAuthorisation';


export default {

  components: { PageHeader, SelectInput, CurrencyInput, PhotoInput, DocumentInput, Widget, FingerprintAuthorisation },

  data() {
    return {
      valid: false,
      expenseTypes: ['Computer expenses', 'Electricity & water', 'Entertainment', 'Internet', 'Legal fees', 'Licencing', 'Local transport', 'Marketing', 'Office cleanliness', 'Office consumables', 'Printing & stationery', 'Rates & refuse', 'Rent', 'Repairs & maintenance', 'Security', 'Telecommunications'],
      expenseType: undefined,
      amount: 0,
      invoice: undefined,
      authorisation: undefined,
      notes:undefined,

      transactions: undefined,
      safeboxOwnerName: undefined,
      balance:  0,

      confirmation: undefined,
    }
  },

  computed: {
  },

  methods: {
    validate() {
      console.log('validate');
      this.$refs.expenseForm.validate();
      console.log('validate2');
    },
    submit() {
      console.log('submit');
    }
  },

  mixins: [lookups],

}
</script>
