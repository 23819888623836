<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div no-gutters v-if="images.length>0">
          <Images :images="images" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Images from "@/components/Images";

export default {

  components: { Images },

  props: {
    customer: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
    }
  },

  computed: {
    images() {
      let i = this.$store.getters.images.filter( e => ( e.type_id===this.customer.uuid ) && (e.type==='customer' || e.type==='demographic') );
      if (this.customer.demographic && this.customer.demographic.imageUrls) { i = i.concat(this.customer.demographic.imageUrls); }
      if (this.customer.demographic && this.customer.demographic.idScanUrls) { i = i.concat(this.customer.demographic.idScanUrls); }
      if (this.customer.imageUrls) { i = i.concat(this.customer.imageUrls) }
      return i;
    },
  },

}
</script>
