<template>
  <span>
    <v-img v-if="logo==='apex_tz'"      :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/apex_tz.png" />
    <v-img v-if="logo==='hr_co'"        :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/hr_co.gif" />
    <v-img v-if="logo==='nile_ug'"      :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/sbg.png" />
    <v-img v-if="logo==='nucafe_ug'"    :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/sbg.png" />
    <v-img v-if="logo==='sasa'"         :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/logo.png" />
    <v-img v-if="logo==='sbg'"          :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/sbg_logo.png" class="text-left" />
    <v-img v-if="logo==='ultimate_tz'"  :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/ultimate_tz.png" />
    <v-img v-if="logo==='two_tz'"       :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/ultimate_tz.png" />
    <v-img v-if="logo==='uzima_ug'"     :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/uzima_ug.png" />
    <v-img v-if="logo==='winwin_zw'"    :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/winwin_zw.jpg" />
    <v-img v-if="logo==='dairy_ke'"     :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/one_farm.png" />
  </span>
</template>

<script>
  import domain from '@/mixins/domain';
  import {customerAppTheme} from "../enums/customer-theme";

  export default {
    props: {
      maxHeight: { type: Number, default: 40 },
      maxWidth:  { type: Number, default: 100 },
    },
    computed: {
      logo() {
        const partnerCode = this.$store.getters.settings.partnerCode;
        let l = 'sasa';
        if (['dairy_ke','apex_tz', 'eni_ke', 'hr_co', 'nile_ug', 'nucafe_ug', 'ultimate_tz', 'two_tz', 'uzima_ug', 'winwin_zw'].includes(partnerCode)) {
          l = partnerCode;
        }

        // todo merge with existing logic
        if (this.$store.getters.customerAppTheme === customerAppTheme.SBG) {
          l = 'sbg'
        }
        if (this.domain==='milk') {
          l = 'dairy_ke';
        }
        return l;
      }
    },
    mixins: [domain],
  };
</script>
