import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import './assets/sass/style.scss'

import { VueMasonryPlugin } from "vue-masonry";
Vue.use(VueMasonryPlugin);

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/assets/css/leaflet.css'

import { Icon } from 'leaflet';
import i18n from './i18n'

import Chartkick from 'vue-chartkick';
import Chart from 'chart.js';
Vue.use(Chartkick.use(Chart));

import VueHtmlToPaper from 'vue-html-to-paper';
const htmlToPaperOptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    './css/pdf-styling.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
Vue.use(VueHtmlToPaper, htmlToPaperOptions);


delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);


Vue.config.productionTip = false

Vue.config.errorHandler = (err, vm, info) => {
  const errorPayload = {message:err.message , stack: err.stack}
  console.error(err)
  store.dispatch('logErrorDetail', { at: Date.now(), err: errorPayload, vm: vm.$vnode.tag, info: info } );
};


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')


