<template>
  
  <v-container fluid>

    <v-row>
      <v-col cols="12">
        <v-radio-group v-model="target" row >
          <v-radio label="Deposit"       value="toBank"   v-if="isSafe" />
          <v-radio label="Withdrawal"    value="fromBank" v-if="isSafe" />
          <v-radio label="To Safe"       value="toSafe"   v-if="!isSafe" />
          <v-radio label="Transfer"      value="consultant" />
        </v-radio-group>
      </v-col>

      <v-col cols="12" class="text-left">
        <span v-if="target=='toBank'" class="text-h4">Transfer to Bank</span>
        <span v-if="target=='fromBank'" class="text-h4">From Bank</span>
        <span v-else-if="target=='toSafe'" class="text-h4">Transfer to Safe Box</span>
        <FieldAgentInput v-else-if="target=='consultant'" v-model="recipient" label="Loan Consultant" online />
      </v-col>

      <v-col cols="12">
        <CurrencyInput v-model="cash" label="Cash Receipted" />
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn color="success" style="width: 100%;" small dense @click="$emit('submit')" :disabled="!valid">Send</v-btn>
      </v-col>
    </v-row>


  </v-container>

</template>

<script>
import FieldAgentInput from "@/components/FieldAgentInput";
import CurrencyInput from "@/components/CurrencyInput";

export default {

  components: {  FieldAgentInput, CurrencyInput },

  props: {
    value: {
      type: Object,
    },
    safe: {
      type: Boolean,
      default: false,
    },
    balance: Number,
  },

  data() {
    return {
      recipient: undefined,
      target: 'consultant',
      cash: 0,
    }
  },

  computed: {
    result() {
      let r = {
        recipient: this.recipient,
        target: this.target,
        cash: this.cash,
      }
      return r;
    },
    isSafe() { return (this.safe); },
    valid() {
      let r = true;
      if (this.target!='fromBank') {
        r = false;
        let cash = (this.cash || 0);
        let balance = (this.balance || 0) / 100;
        if ((cash>0) && (cash<=balance)) {
          r = true;
        }
      }
      if (this.target==='consultant') {
        r = r && !!this.recipient;
      }
      return r;
    }
  },

  watch: {
    result: {
      deep: true,
      handler(to) {
        this.$emit('input', to);
      }
    },
  },

}
</script>
