<template>
  <v-input :rules="[requiredRule]" v-model="imageId">
    <TakePhoto @click="saveImage" :disabled="disabled" buttonColour="buttonColour" :width="width">
      <slot>
        <v-btn :color="color" :small="small" :dense="dense" :disabled="disabled" width="100%"><v-icon dense>{{icon}}</v-icon> {{caption}}</v-btn>
      </slot>
    </TakePhoto>
  </v-input>
</template>

<script>
  import TakePhoto from '@/components/TakePhoto';

  export default {

    components: { TakePhoto },

    props: {
      value: String,
      label: String,
      icon: {
        type: String,
        default: "mdi-camera",
      },
      type: {
        type: String,
        required: true,
        validator(value) { return ['selfie', 'id_scan', 'field', 'assessment', 'payslip', 'bank_statement', 'direct_debits', 'id_card_front', 'id_card_back', 'work_id', 'credit_check', 'transactional'].includes(value); },
      },
      typeId: String,
      required: Boolean,
      dense: Boolean,
      small: Boolean,
      width: {
        type: [String, Number]
      }
    },

    data: () => ({
      imageId: undefined,
      validationProp: false,
    }),

    computed: {
      caption() {
        return this.label || this.$t(this.type.replace('_',' '));
      },
      color() {
        return ( this.imageId ? 'success' : 'primary' );
      },
      remoteLookupApiKey() { return this.$store.getters.apiKey; },
      disabled() { return ( !(this.type==='transactional' || this.typeId) ); },
    },

    watch: {
      value: {
        immediate: true,
        handler(to) {
          this.imageId = to;
        }
      },
      imageId(to) { this.$emit('input', to); },
    },

    methods: {
      saveImage(uuid) {
        this.imageId = uuid;
        this.$store.dispatch('addImage', { type: this.type, type_id: this.typeId, uuid: uuid, 'authorization': this.remoteLookupApiKey });
      },
      requiredRule(value) {
        let r = [
          v => !this.required || !!((v || '').trim()) || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
    }


  }
</script>


<i18n>
{
  "en": {
    "selfie": "Selfie",
    "id scan": "ID Scan",
    "assessment": "Assessment"
  },
  "es": {
    "selfie": "Autofoto",
    "id scan": "Identificación",
    "assessment": "Evaluación"
  },
  "sw": {
    "selfie": "Selfie",
    "id scan": "Utambulisho",
    "assessment": "Tathmini"
  }
}
</i18n>
