export default {
  data() {
    return {
      debugNavigator: navigator,
      mediaDevices: [],
      isBluetoothAvailable: null,
      connectedBluetoothDevice: null,
      bluetoothDevice: null,
      btWeighScaleRawValue: 0,
      btDemoReadings: false,
      btScaleTimer: null,
      stability: null,
      altstability: null,
    }
  },

  methods: {

    bluetoothDisconnectServer() {
      if (!!this.bluetoothDevice) {
        this.bluetoothDevice.gatt.disconnect();
        this.bluetoothDevice = null
        this.connectedBluetoothDevice = null
      }
    },

    bluetoothScan() {
      const bleService = 0xFFE0;
      // const bleCharacteristic = 0xFFE1;

      const altbleService = '0000ffb0-0000-1000-8000-00805f9b34fb'
      // const altbleCharacteristic = '0000ffb2-0000-1000-8000-00805f9b34fb'
      // service uuid has been the same for the 2 scales
      const optionalDevice = BluetoothUUID.getService('0000ffe0-0000-1000-8000-00805f9b34fb')

      console.log("blue tooth device: ", optionalDevice)
      navigator.bluetooth.requestDevice({
        // filters: [...] <- Prefer filters to save energy & show relevant devices.
        // acceptAllDevices: true,
        // filters: [{namePrefix: "Scale-"}],
        // optionalServices: [bleService,altbleService],
        optionalServices: [optionalDevice],
        // optionalServices: ['device_information'],
        acceptAllDevices: true
      })
          .then(device => {
            this.bluetoothDevice = device
            return device.gatt.connect();
          })
          .then(server => {
            // Note that we could also get all services that match a specific UUID by
            // passing it to getPrimaryServices().
            this.connectedBluetoothDevice = server;
            console.log('Getting Services...', server);
            // const ress = await server.getPrimaryServices()
            // this.readServer(server)
            // debugger
            return server.getPrimaryServices();
          })
          .then(services => {
            // check number of services
            console.log('services', services);
            if (services.length > 0) {
              return services[0].getCharacteristics();
            } else {
              // todo
              throw error
            }

          })
          .then(characteristics => {
            console.log('characteristics.length', characteristics.length);
            const myCharacteristic = characteristics[0]
            console.log('selected', myCharacteristic);

            return myCharacteristic.startNotifications().then(_ => {
              myCharacteristic.addEventListener('characteristicvaluechanged',
                  this.handleNotifications);
            });
          })
          .catch(error => {
            console.log('Argh! ' + error);

          });
    },

    handleNotifications(event) {
      let value = event.target.value;
      let a = [];
      let parsedValue = ''
      for (let i = 0; i < value.byteLength; i++) {
        a.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
        parsedValue += String.fromCharCode(value.getUint8(i))

      }
      
      // was required for version one of the scale

      // if (parsedValue && parsedValue.split('=').length === 2) {
      //   this.btWeighScaleRawValue = parseFloat(parsedValue.split('=')[1])
      //   console.log("btWeight: ", this.btWeighScaleRawValue)
      // }

      // if (parsedValue.replace(/\s/g, "").split(',').length > 2) {
      //   const rawValue = parsedValue.replace(/\s/g, "").split(',').pop()
      //   this.btWeighScaleRawValue = parseFloat(rawValue.replace(/\s/g, "kg"))
      //   console.log("btWeight: ", this.btWeighScaleRawValue)
      // }
      //

      this.btWeighScaleRawValue = parseFloat(parsedValue.substring(5, parsedValue.length - 2).trim()) || 0
      this.stability  = parsedValue.charAt(1); // Extract the first character
      this.altstability  = parsedValue[4];
      console.log("val: ", parsedValue)
      console.log("stability status 1: ", this.stability)
      console.log("stability status 2: ", this.altstability)
    },

    clearScaleValue() {
      this.btWeighScaleRawValue = 0
    },

    manipulateBluetoothDevice() {
      this.bluetoothDevice = this.bluetoothDevice === null ? 'Samsung' : null;
    }

  },

  mounted() {
    if (navigator.bluetooth) {
      navigator.bluetooth.getAvailability()
          .then(isBluetoothAvailable => {
            this.isBluetoothAvailable = isBluetoothAvailable
          }).catch(error => {
        console.log(`> Bluetooth availability error`);
      });
    } else {
      this.isBluetoothAvailable = false
    }
    this.btScaleTimer = setInterval(() => {
      if (this.btDemoReadings) {
        this.btWeighScaleRawValue = parseFloat( ( this.btWeighScaleRawValue + Math.random() ).toFixed(1) );
      }
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.btScaleTimer)
  },

  destroyed() {
    this.bluetoothDisconnectServer();
    this.btWeighScaleRawValue=null
  },

}
