<template>
  <v-container fluid>

    <v-row class="text-left">
      <v-col cols="6" class="text-h4">
        {{customer.name}}
      </v-col>
    </v-row>

    <v-row>

      <v-col cols="6" class="text-left">
        <TestCard class="mx-auto" title="Raise Monthly Interest" :results="interestRaised" @test="raiseInterest">
          <template v-slot:Inputs>
            <v-row>
              Raise Monthly Interest
            </v-row>
          </template>
          <template v-slot:Results>
            <div class="text-h4">
              Done
            </div>
          </template>
        </TestCard>
      </v-col>

      <v-col cols="6" class="text-left">
        <TestCard class="mx-auto" title="Age Loan" :results="loanAged" @test="ageLoan">
          <template v-slot:Inputs>
            <v-row>
              Age Loan
            </v-row>
          </template>
          <template v-slot:Results>
            <div class="text-h4">
              Done
            </div>
          </template>
        </TestCard>
      </v-col>

    </v-row>


  </v-container>
</template>

<script>

import lookups from '@/mixins/lookups';

import TestCard from '@/views/tests/TestCard';

export default {

  components: { TestCard },

  data() {
    return {
      interestRaised: undefined,
      loanAged: undefined,
    }
  },

  computed: {
    uuid() { return this.$route.query.uuid; },
    customer() { return this.$store.getters.customer(this.uuid); },
  },

  methods: {
    raiseInterest() {
      this.remoteLookup('testUltimateTzRaiseInterest', {
        uuid: this.customer.uuid
      }).then( installment => this.interestRaised = true ).catch(e => this.interestRaised = null);
    },
    ageLoan() {
      this.remoteLookup('testUltimateTzAgeLoan', {
        uuid: this.customer.uuid
      }).then( e => this.loanAged = true ).catch(e => this.loanAged = null);
    },
  },

  mixins: [lookups],

}
</script>
