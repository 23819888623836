<template>
  <v-input :rules="[requiredRule]" v-model="authCode" class="pa-2" style="width: 100%;">
    <v-card style="width: 100%;" :loading="true" loader-height="4" elevation="4">
      <template slot="progress">
        <v-progress-linear color="red" indeterminate></v-progress-linear>
      </template>
      <v-img height="220px" :src="image" />
      <v-card-actions v-if="!this.production">
        <v-btn color="error" block @click="cheat">
          Cheat
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-input>
</template>


<script>
  import prodDebug from "@/mixins/prodDebug";
  import lookups from "@/mixins/lookups";
  import { Base64 } from '@digitalpersona/core';
  import VInput from 'vuetify/lib/components/VInput/VInput.js';
  import { FingerprintReader, SampleFormat } from '@digitalpersona/devices';

  export default {

    props: {
      value: {
        type: String,
        required: false,
      },
      user: {
        type: Boolean,
        default: true,
      },
      customer: {
        type: Object,
        required: false,
      },
      msisdn: {
        type: String,
        required: false,
      },
      partnerCode: {
        type: String,
        required: false,
      },
    },

    data: () => ({
      sample: undefined,
      unavailable: false,
      connected: false,
      reader: undefined,
      acquisitionStarted: false,
      authCode: undefined,
      theCustomer: undefined,
    }),

    computed: {
      image() { return (this.sample ? "data:image/png;base64," + Base64.fromBase64Url(this.sample) : undefined); },
      strategy() { return (this.theCustomer ? 'customer' : 'msisdn') },
    },

    watch: {
      authCode(to) {
        this.$emit('input', to);
      },
      sample(to) {
        if (this.sample) {
          console.log('send to server for auth and get result');
          let params = {
            sample: 'seems png is too big (should us to from method param)',
            strategy: this.strategy,
            msisdn: this.msisdn,
            partnerCode: this.partnerCode,
            uuid: (this.theCustomer || {}).uuid,
          }
          this.remoteLookup('fingerprintAuth', params).then( authCode => this.authCode = authCode.authCode ).catch(e => this.authCode = null);
        }
      },
    },

    methods: {

      cheat() {
        this.sample = (new Date()).getTime().toString();
      },

      requiredRule(value) {
        let r = [
          v => !!((v || '').trim()) || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },

      onSamplesAcquired(s) {
        console.log("this is s: ", s.samples);
        console.log("this is also s: ", s);
        this.sample = s.samples[0];
        console.log('tta');
      },

      startCapture() {
        console.log('start capture');
        if (this.acquisitionStarted) return;
        // this.reader = new FingerprintReader();
        var currentFormat = SampleFormat.PngImage;
        console.log("reader: ", this.reader)
        console.log("acquisition: ", this.acquisitionStarted)
        this.reader.startAcquisition(currentFormat).then(() => {
          this.acquisitionStarted = true
          console.log("starting the capture process: ")
          //Disabling start once started
          // this.disableEnableStartStop();
        });
      },
    },



    mounted () {

      if (this.user) {
        this.theCustomer = { uuid: this.$store.getters.settings.fieldAgentUuid };
      } else {
        this.theCustomer = this.customer;
      }

      this.reader = new FingerprintReader();

      this.reader.onDeviceConnected = (e) => {
            // Detects if the deveice is connected for which acquisition started
        this.connected = true
        console.log("Scan your finger");
        console.log("nothing is beeing loged")
      }
      
      this.reader.onDeviceDisconnected = (e) => {
          // Detects if device gets disconnected - provides deviceUid of disconnected device
        this.connected = false
        console.log("Device disconnected");
        console.log("its not active any more")
        console.log("nothing is beeing logged")
      }

      this.reader.onSamplesAcquired = (s) => {
        this.onSamplesAcquired(s);
      }
    
      this.reader.onQualityReported = (quality) => {
        console.log("quality : ", quality.quality);
      }

      if (this.production) {
        console.log('start scanning');
        this.startCapture();
      }
      
    },

    mixins: [prodDebug, lookups],

  }
</script>