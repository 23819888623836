<template>

  <v-data-table dense :headers="headers" :items="approvals" item-key="approval" class="elevation-1" @click:row="click">

    <template v-slot:item.created_at="{ item }">
      <Time :time="item.created_at" />
    </template>

    <template v-slot:item.status="{ item }">
      <code>{{item.status}}</code>
    </template>

    <template v-slot:item.customer_name="{ item }">
      <ToCustomer :uuid="item.customer_uuid" :name="item.customer_name"/>
    </template>

  </v-data-table>
    
</template>


<script>
  import Time from "@/components/Time";
  import ToCustomer from "@/components/ToCustomer";

  export default {

    components: { Time, ToCustomer },

    props: {
      approvals: Array,
    },

    data: () => ({
      headers: [
        {
          text: 'Created',
          align: 'start',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Approval',
          align: 'start',
          sortable: true,
          value: 'assessment_name',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: true,
          value: 'description',
        },
        {
          text: 'Customer',
          align: 'start',
          sortable: true,
          value: 'customer_name',
        },
        {
          text: 'Reviewer',
          align: 'start',
          sortable: true,
          value: 'reviewer',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status',
        },
      ],
    }),

    methods: {
      click(item) {
        this.$router.push({ name: 'ApprovalShow', query: { uuid: item.uuid }});
      }
    }

  }
</script>
