<template>
  <v-form v-model="validForm" ref="contactForm">
    <v-container>

      <v-row class="blue lighten-5">
        <v-col cols="12" sm="6">
          <MsisdnInput v-model="customer.msisdn" />
        </v-col>
        <v-col cols="12" sm="6">
          <EmailInput v-model="customer.email" />
        </v-col>
      </v-row>

      <v-row class="blue lighten-5">
        <v-col cols="12" sm="4">
          <NameInput v-model="customer.firstName" :label="$t('First Name')" />
        </v-col>
        <v-col cols="12" sm="4">
          <NameInput v-model="customer.middleName" :label="$t('Middle Name')" />
        </v-col>
        <v-col cols="12" sm="4">
          <NameInput v-model="customer.surname" :label="$t('Surname')" />
        </v-col>
      </v-row>

      <v-row class="green lighten-5">
        <v-col cols="12" sm="4">
          <DateInput
            v-model="customer.demographic.birthdate"
            :label="$t('Birthdate')"
            min="1910-01-01"
            :max="new Date().toISOString().substr(0, 10)"
            year="true"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <GenderInput v-model="customer.gender" />
        </v-col>
        <v-col cols="12" sm="4">
          <MaritalStatusInput v-model="customer.demographic.maritalStatus" />
        </v-col>
       
      </v-row>


      <v-row class="green lighten-5">
        <v-col cols="12" sm="4">
          <v-text-field v-model="customer.church" :rules="churchNameRules" :label="$t('Church')" />
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="customer.demographic.dependents"
            :label="$t('Dependents')"
            :items="[0,1,2,3,4,5,6,7,8]"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <LanguageInput v-model="customer.demographic.language" />
        </v-col>
      </v-row>

      <v-row class="green lighten-5">
        <v-col cols="12">
          <v-radio-group v-model="town" row dense>
            <v-radio label="Lusaka" value="Lusaka"></v-radio>
            <v-radio label="Ndola" value="Ndola"></v-radio>
            <v-radio label="Mazabuka" value="Mazabuka"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row class="yellow lighten-5 pb-3">
        <v-col cols="12" lg="6">
          <FarmingActivities title="Livestock" icon="mdi-cow" type="livestock" :activities="['poultry', 'pigs', 'dairy', 'beef', 'small stock', 'other_livestock']" />
        </v-col>
      </v-row>

      <v-row class="blue-grey lighten-5">
        <v-col cols="12">
          <v-textarea
            :label="$t('Notes')"
            v-model="customer.note"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <div class="text-left">
              <v-btn @click="$emit('clearInputs')" class="d-none d-sm-inline">{{$t('Clear')}}</v-btn>
            </div>
            <div class="text-right">
              <v-btn @click="$emit('submitAndEdit')" color="primary" :disabled="!validForm" class="mr-2">{{$t('SaveAndViewDetail')}}</v-btn>
              <v-btn @click="$emit('submitAndNew')" color="primary" :disabled="!validForm">{{$t('SaveAndAddNew')}}</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </v-form>
</template>


<script>

  import MsisdnInput from '@/components/MsisdnInput';
  import EmailInput from '@/components/EmailInput';
  import NameInput from '@/components/NameInput';
  import DateInput from '@/components/DateInput';
  import MaritalStatusInput from '@/components/MaritalStatusInput';
  import GenderInput from '@/components/GenderInput';
  import LanguageInput from '@/components/LanguageInput';
  import FarmingActivities from '@/views/farmers/components/FarmingActivities';

  export default {

    components: {
      MsisdnInput,
      EmailInput,
      NameInput,
      DateInput,
      MaritalStatusInput,
      GenderInput,
      LanguageInput,
      FarmingActivities,
    },

    props: ['customer'],

    data: () => ({
      validForm: false,
      town: undefined,
      regions: {
        "Lusaka": ['Lusaka Province','Lusaka District','Lusaka'],
        "Ndola": ['Copperbelt Province','Ndola District','Ndola'],
        "Mazabuka": ['Southern Province','Mazabuka Distict','Mazabuka'],
      },
      churchNameRules: [
        // v => !!v || 'Required',
        v => /^.{0,100}$/.test(v) || 'Must be less than 100 characters',
        v => !(/[^\w-\s]/.test(v)) || 'Characters only',
        v => !(/\d/.test(v)) || 'No numbers',
        v => (v || '').length==0 || /^\S/.test(v) || 'No spaces at start',
        v => (v || '').length==0 || /\S$/.test(v) || 'No spaces at end',
      ],
    }),

    watch: {
      town: function(to) { this.customer.regions = this.regions[to]; },
    },
    methods: {
      resetForm() {
        this.$refs.contactForm.reset()
      }
    }
  }
</script>


<i18n>
{
  "en": {
    "First Name": "First Name",
    "Middle Name": "Middle Name",
    "Surname": "Surname",
    "Birthdate": "Birthdate",
    "Church": "Church",
    "Notes": "Notes",
    "SaveAndEdit": "Save & Edit",
    "SaveAndAdd": "Save & Add",
    "Clear": "Clear"
  },
  "es": {
    "First Name": "Primer nombre",
    "Middle Name": "Segundo nombre",
    "Surname": "Apellido",
    "Birthdate": "Fecha de nacimiento",
    "Church": "Iglesia",
    "Notes": "Notas",
    "SaveAndEdit": "Salvar o Editar",
    "SaveAndAdd": "Salvar y añadir (Otro Contacto)",
    "Clear": "Reiniciar"
  },
  "sw": {
    "First Name": "Jina la kwanza",
    "Middle Name": "jina la Kati",
    "Surname": "Jina la jina",
    "Birthdate": "Tarehe ya kuzaliwa",
    "Church": "Kanisa",
    "Notes": "Noti",
    "SaveAndEdit": "Hifadhi na hariri",
    "SaveAndAdd": "Hifadhi na sajili mwingine",
    "Clear": "Futa"
  }
}
</i18n>
