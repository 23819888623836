<template>
  <v-sheet elevation="2">
    <v-form v-model="valid">
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <div class="d-flex flex-column">
              <div>
                <div class="text-h5"><Telephone :msisdn="customer.msisdn" /></div>
                <div class="text-caption">{{customer.name}}</div>
              </div>
              <div>&nbsp;</div>
              <div>
                <div>
                  <FutureInput v-model="follow_up_at" label="Follow Up" required />
                </div>
                <div>
                  <v-btn @click="submit('phone_call')" :disabled="!valid" color="primary" small dense class="ma-1"><v-icon dense>mdi-phone-in-talk</v-icon></v-btn>
                  <v-btn @click="submit('no_answer')"  :disabled="!valid" color="primary" small dense class="ma-1"><v-icon dense>mdi-phone-off</v-icon></v-btn>
                  <v-btn @click="submit('p2p')"        :disabled="!valid" color="primary" small dense class="ma-1"><v-icon dense>mdi-handshake</v-icon> P2P</v-btn>
                  <v-btn @click="submit('escalate')"   :disabled="!valid" color="warning" small dense class="ma-1"><v-icon dense>mdi-escalator-up</v-icon> Escalate</v-btn>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="8">
            <v-textarea solo v-model="note" label="Notes" />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-sheet>
</template>


<script>
import upsertActions from '@/mixins/models/actions'

import Telephone from "@/components/Telephone";
import FutureInput from "@/components/FutureInput";

export default {

  components: { Telephone, FutureInput },

  props: ['customer'],

  data() {
    return {
      follow_up_at: undefined,
      valid: false,
      note: '',
    }
  },

  methods: {
    submit(action) {
      this.upsertAction({ target: this.customer, follow_up_at: this.follow_up_at, action: action, note: this.note });
      this.$emit('close');
    }
  },

  mounted() {
    this.follow_up_at = undefined;
    this.valid = false;
    this.note = '';
  },

  mixins: [upsertActions],

}
</script>