<template>
  <InstrumentUltimateTzCheckMeBack   :instrument="instrument" :instrumentId="instrumentId" :customer="customer" :highlight="highlight" @instrument="loaded" v-if="instrumentType=='InstrumentUltimateTzCheckMeBack'"   />
  <InstrumentUltimateTzSalaryAdvance :instrument="instrument" :instrumentId="instrumentId" :customer="customer" :highlight="highlight" @instrument="loaded" v-else-if="instrumentType=='InstrumentUltimateTzSalaryAdvance'" />
  <InstrumentUltimateTzGovernment :instrument="instrument" :instrumentId="instrumentId" :customer="customer" :highlight="highlight" @instrument="loaded" v-else-if="instrumentType=='InstrumentUltimateTzGovernment'" />
</template>

<script>
import InstrumentUltimateTzCheckMeBack from "@/views/instruments/InstrumentUltimateTzCheckMeBack"
import InstrumentUltimateTzSalaryAdvance from "@/views/instruments/InstrumentUltimateTzSalaryAdvance"
import InstrumentUltimateTzGovernment from "@/views/instruments/InstrumentUltimateTzGovernment"

export default {

  components: { InstrumentUltimateTzCheckMeBack, InstrumentUltimateTzSalaryAdvance },

  // note, if you pass an instrumentId, you must also pass a customer.
  props: {
    instrument: Object,
    instrumentId: Number,
    customer: Object,
    highlight: String,
    instrumentType: String
  },

  computed: {
    theInstrumentType() { return (this.instrumentType ? this.instrumentType : (this.instrument ? this.instrument.type : undefined) ); },
  },

  methods: {
    loaded(to) { this.$emit('instrument', to); },
  },

}
</script>
