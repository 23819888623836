<template>
  <PageHeader title="Safebox" icon="mdi-bank">
    
    <v-container fluid>

      <v-row>

        <v-col cols="6" class="text-left">
          <div class="mb-4">
            <Widget title="Balance">
              <Currency :value="balance" cents />
            </Widget>
          </div>
          <div>
            <Widget title="Send Money">
              <Capture @submit="toMove" v-model="transfer" safe :balance="balance" />
            </Widget>
          </div>
        </v-col>


        <v-col cols="6" class="text-left">
          <Widget title="End Of Day Balance">
            <EndOfDayBalance v-if="hasSafe" />
          </Widget>
        </v-col>

      </v-row>

      <v-row>

        <v-col cols="6" class="text-left">
        </v-col>

      </v-row>


      <v-row>
        <v-col cols="12" class="text-h5 text--primary text-left">
          Transaction History
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <ServerSideResult :test="transactions">
            <v-data-table
              :headers="headers"
              :items="transactions"
              :items-per-page="25"
              class="elevation-1"
            >
            
              <template v-slot:item.at="{ item }">
                <Time v-if="item.at" :time="item.at" />
              </template>
            
              <template v-slot:item.amount="{ item }">
                <Currency v-if="item.amount" :value="item.amount" cents />
              </template>
            
              <template v-slot:item.after="{ item }">
                <Currency v-if="item.after" :value="item.after" cents />
              </template>

            </v-data-table>

          </ServerSideResult>
        </v-col>
      </v-row>

    </v-container>

  </PageHeader>

</template>

<script>
import lookups from '@/mixins/lookups';

import PageHeader from "@/components/PageHeader";
import Time from "@/components/Time";
import Currency from "@/components/Currency";
import ServerSideResult from "@/components/ServerSideResult";
import Widget from "@/components/Widget";

import Capture from "@/views/partners/ultimate_tz/cashbox/Capture";
import EndOfDayBalance from "@/views/partners/ultimate_tz/cashbox/EndOfDayBalance";



export default {

  components: { PageHeader, EndOfDayBalance, Time, Currency, ServerSideResult, Widget, Capture },

  data() {
    return {
      safeboxOwnerName: undefined,
      balance:  0,

      transactions: undefined,

      transfer: {},

      headers: [
        {
          text: 'When?',
          align: 'start',
          sortable: true,
          value: 'at',
        },
        {
          text: 'What?',
          align: 'start',
          sortable: true,
          value: 'type',
        },
        {
          text: 'Who?',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Amount',
          align: 'end',
          sortable: true,
          value: 'amount',
        },
        {
          text: 'Balance',
          align: 'end',
          sortable: true,
          value: 'after',
        },
      ],
    }
  },

  computed: {
    hasSafe() { return this.$store.getters.hasUltimateSafe; },
  },

  watch: {
  },

  methods: {
    setSafeFromServer(safe) {
      this.$store.commit('setUltimateSafe', safe);
    },
    setWalletInfo(wallet) {
      this.safeboxOwnerName = ( wallet.user_has_safe ? 'You' : 'Nobody' );
      this.balance = wallet.safe_balance;
      this.$store.commit('setUltimateSafe', (wallet.user_has_safe ? true : false) );
    },
    toMove() {
      let params = this.transfer || {};
      params['recipient_id'] = (this.transfer.recipient || {}).id;
      params['name'] = (this.transfer.recipient || {}).name;
      params['safe'] = 'safe';
      this.$router.push({ name: 'UltimateTzCashboxSend', query: params });
    },
  },

  mounted() {
    this.remoteLookup('ultimateTzWalletInfo', {}).then( wallet => this.setWalletInfo(wallet) ).catch(e => console.log(e));
    this.remoteLookup('ultimateTzWalletTransactions', { safe: true }).then( transactions => this.transactions = transactions ).catch(e => this.transactions = null);
  },

  mixins: [lookups],

}
</script>
