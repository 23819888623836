<template>
  <transition>
    <keep-alive>
      <div>
        <v-card class="pa-2 ma-1 w-100">
          <router-view :class="custom_font ? 'custom-font' : ''"/>
        </v-card>

        <Footer />
      </div>
    </keep-alive>
  </transition>
</template>

<script>
import domain from '@/mixins/domain';

import Footer from '@/views/login/components/partners/dairy_ke/Footer';

export default {
  components: { Footer },
  computed: {
    custom_font() {
      return (this.domain === 'milk')
    },
  },

  methods: {
    isActiveLink(path) {
      if (path == '/home' && this.$route.path == '/dairy/collect') {
        return true
      } else {
        return this.$route.path.includes(path)
      }
    }
  },
  
  mixins: [domain],
}
</script>

